import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DashboardStyled = styled.section`
  ${({ theme }) => css`
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  header {
    display: flex;
    align-items: center;
    padding: 5px 0;

    h3 {
      margin-bottom: 0px;
    }

    nav {
      display: flex;
      flex: 1;
      justify-content: end;
      min-height: 56px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        gap: ${theme.spacing(3)};

        li {
          display: flex;
          align-items: center;
        },
      },
    },
  }

  .mainContent {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-left: 6px;
  }

  .Container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .spotlight {
    font-size: 36px;
    text-align: center;
    font-weight: 500;   
  }

  .text {
    font-size: 27px;
    text-align: center
  }

  .buttonsDown {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
  }

  .createD {
    min-width: 230px;
  }
  
  .addW {
    background-color: #124E88;
    min-width: 180px;
    color: #F5F5F5;
    &:hover {
      color: #124E88;
      background-color: #F5F5F5;
    };
  }
`}
`;

export const ContainerStyled = styled.div`
  ${({ theme }) => css`
    width: 600px;
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};
    border-radius: 20px 20px 0 0;
    border: 1px solid #cac4d0;
    box-shadow: 0px 4px 20px 0px rgba(101, 119, 136, 0.2);
    padding: 20px;
    border-bottom: none;
  `}

  .MuiTypography-root {
    display: none;
  }
`;

export const PowerBIStyles = css`
  .report-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: transparent;
    margin: 0;
    border: none;
    width: 100%;
  }

  .report-container iframe {
    border: none;
  }

  .report-container .widget {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    isolation: isolate;

    position: absolute;
    width: 305.67px;
    height: 262.69px;
    left: 165px;
    top: 229px;

    /* light mode/light blue shadow */
    filter: drop-shadow(0px 4px 20px rgba(101, 119, 136, 0.2));
    border-radius: 12px;

    box-sizing: border-box;

    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* Neutral/100 */
    background: #ffffff;
    /* M3/sys/light/outline-variant */
    border: 1px solid #cac4d0;
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;

    /* state-layer */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* content-container */
    box-sizing: border-box;

    width: 305.67px;
    height: 262.69px;

    /* M3/sys/light/outline-variant */
    border: 1px solid #cac4d0;
    border-radius: 12px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    z-index: 1;

    /* Media & Text content */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0px 20px 10px;

    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* header */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px 4px 12px 16px;
    width: 295.67px;
    height: 72px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    /* content */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 0px 0px;
    gap: 16px;
    width: 222px;
    height: 39.51px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;

    /* Vector */
    width: 40.64px;
    height: 32.51px;

    /* Green/60 */
    background: #4fbb75;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* title */
    width: 166px;
    height: 20px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */
    /* Neutral/40 */
    color: #605d62;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    /* icon-button */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 48px;
    height: 48px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    /* container */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 56px;
    height: 56px;
    border-radius: 100px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* state-layer */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    width: 56px;
    height: 56px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* Icons/more_vert_24px */
    width: 40px;
    height: 40px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* Icons/more_vert_24px
    more, vert, vertical, three, dots, etc, 3, android
    */
    position: absolute;
    width: 24px;
    height: 24px;
    left: calc(50% - 24px / 2 - 0px);
    top: calc(50% - 24px / 2 - 12px);

    /* icon */
    position: absolute;
    left: 41.67%;
    right: 41.67%;
    top: 16.67%;
    bottom: 16.67%;

    /* M3/sys/light/outline
    token=md.sys.color.outline
    */
    background: #79747e;

    /* Frame 218 */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 28px;
    width: 5.67px;
    height: 25.67px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

    /* Text content */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px;
    gap: 32px;
    width: 295.67px;
    height: 99px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;

    /* headline */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 19px;
    width: 263.67px;
    height: 59px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    /* title */
    width: 274px;
    height: 20px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
    /* identical to box height, or 71% */
    /* Neutral/40 */
    color: #605d62;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* subhead */
    width: 274px;
    height: 20px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    /* identical to box height, or 83% */
    letter-spacing: 0.25px;
    /* Neutral/70 */
    color: #aeaaae;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

    /* Frame 34 */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 15px;
    width: 267px;
    height: 43px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

    /* Frame 33 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px;
    gap: 10px;
    width: 84px;
    height: 61px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* Frame 30 */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 84px;
    height: 41px;

    /* Green/95 */
    background: #e6f5eb;
    border-radius: 20px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* +18.7 % */
    width: 64px;
    height: 21px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #4fbb75;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    /* From Yesterday */
    width: 136px;
    height: 21px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #4fbb75;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;

import { useState, useMemo } from 'react';
import { Formik, useFormikContext } from 'formik';
import { pick } from 'lodash';
import { Localization, useNotification, Core } from 'connex-cds';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AddOutlined } from '@mui/icons-material';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { defaultValues, validationSchema } from './form-config';
import WidgetTab from '../widget-tab';
import { useUpdateDashboard } from '../../../../api/hooks';
import { useListWidgets } from '../../../../api/hooks/widgets';
import { DialogTitle } from '../../../../commons';
import { DialogStyled } from './styles';

const DetailsDialog = ({ open = false, onClose, editDashboard, widgetList, areWidgetsLoading }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { mutateAsync: updateDashboard } = useUpdateDashboard();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { values, ...formikBag } = useFormikContext();
  const { isSubmitting, dirty, isValid } = formikBag;
  const ADD_WIDGET_TABS = [
    {
      stringId: 'general_widget',
      component: <WidgetTab editDashboard={editDashboard} widgetList={widgetList} areWidgetsLoading={areWidgetsLoading} />,
      icon: <WidgetsOutlinedIcon />,
    },
  ];

  const handleClose = response => {
    onClose(response);
    setCurrentTab(0);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const handleOnSubmit = async () => {
    const { selectedWidgets } = values;
    formikBag.setSubmitting(true);

    const widgets = Object.values(selectedWidgets).flat(Infinity).map(widget => {
      return pick(widget, ['widgetId', 'name', 'type', 'options', 'dataSource', 'queryIdentifier']);
    });

    let response;
    try {
      const dashboard = {
        ...editDashboard,
        widgets: editDashboard.widgets.concat(widgets) || [],
      };
      response = await updateDashboard({ dashboard });
      notification.success(translateMessage('notification_newWidgetsAdded'));
    } catch (error) {
      console.error(error);
    }

    formikBag.setSubmitting(false);
    formikBag.resetForm();
    handleClose(response);
  };

  return (
    <DialogStyled className="add-widget-dialog" open={open} maxWidth="lg" fullWidth onClose={handleCloseDialog}>
      <DialogTitle className="dialog-title" onClose={handleClose}>
        <AddOutlined className="add-icon" />
        <Localization.Translate stringId="dashboards_addWidget_title" />
      </DialogTitle>
      <>
        <DialogContent className="dialog-content" dividers>
          <div className="tab-content">{ADD_WIDGET_TABS[currentTab].component}</div>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            variant="contained"
            disabled={isSubmitting || !dirty || !isValid}
            onClick={handleOnSubmit}
          >
            <Localization.Translate stringId="addWidgetDialog_dialogAction_save" />
          </Button>
        </DialogActions>
      </>
    </DialogStyled>
  );
};

export default ({ editDashboard, ...props }) => {
  const { data: widgetList, isLoading: areWidgetsLoading } = useListWidgets();
  const initialValues = useMemo(() => {
    if (editDashboard) {
      const { id, name, widgets } = editDashboard;
      let selectedWidgets = {};
      widgets?.forEach(widget => {
        const widgetName = widget.widgetId.replace(/\-?\d*$/g, '');
        if (selectedWidgets[widgetName]) {
          selectedWidgets[widgetName].push(widget);
        } else {
          selectedWidgets[widgetName] = [widget];
        }
      });
      const editValues = {
        selectedWidgets: selectedWidgets || {},
      };
      return editValues;
    } else {
      defaultValues.selectedWidgets = {};
      widgetList?.forEach(widget => {
        defaultValues.selectedWidgets[widget.widgetId] = [];
      });
    }

    return defaultValues;
  }, [editDashboard, widgetList]);

  return (
    <Core.Spinner spin={areWidgetsLoading}>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        <DetailsDialog {...props} editDashboard={editDashboard} widgetList={widgetList} areWidgetsLoading={areWidgetsLoading} />
      </Formik>
    </Core.Spinner>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { GridPanel } from '@mui/x-data-grid-pro';
import TablePanelStyles from './style';

const Styled = styled(GridPanel)`
  ${TablePanelStyles}
`;

const TablePanel = props => {
  return <Styled {...props} />;
};

export { TablePanel };

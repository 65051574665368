import React from 'react';
import styled from '@emotion/styled';
import { GridColumnsPanel } from '@mui/x-data-grid-pro';
import TableColumnPanelStyles from './style';

const Styled = styled(GridColumnsPanel)`
  ${TableColumnPanelStyles}
`;

const TableColumnsPanel = props => {
  return <Styled {...props} autoFocusSearchField />;
};

export { TableColumnsPanel };

import { http } from 'connex-cds';

export default {
  createDashboard: ({ entityRef, dashboard }) => {
    return http.post({ apiName: 'dashboards-exp', path: `/${entityRef}/dashboards`, data: dashboard });
  },
  getDashboard: ({ entityRef, dashboardRef }) => {
    return http.get({ apiName: 'dashboards-exp', path: `/${entityRef}/dashboards/${dashboardRef}` });
  },
  listDashboards: ({ entityRef }) => {
    return http.get({ apiName: 'dashboards-exp', path: `/${entityRef}/dashboards` });
  },
  updateDashboard: ({ entityRef, dashboard }) => {
    return http.put({
      apiName: 'dashboards-exp',
      path: `/${entityRef}/dashboards/${dashboard.crn}`,
      data: dashboard,
    });
  },
  deleteDashboard: ({ entityRef, dashboardRef }) => {
    return http.delete({ apiName: 'dashboards-exp', path: `/${entityRef}/dashboards/${dashboardRef}` });
  },
  getDefaultDashboard: ({ entityRef }) => {
    return http.get({ apiName: 'dashboards-exp', path: `/${entityRef}/dashboards/default` });
  },
  setDefaultDashboard: ({ entityRef, dashboardRef }) => {
    return http.post({ apiName: 'dashboards-exp', path: `/${entityRef}/dashboards/${dashboardRef}` });
  },
};

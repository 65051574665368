import React, { useEffect, useState, useRef, useCallback } from 'react';
import { debounce } from 'lodash';
import { Localization } from 'connex-cds';
import { InputAdornment } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import { normalizeText } from './searchFilter.helpers';

import { SearchBarStyled } from './styles';

const SearchFilter = ({ onSearchChange, disabled, options }) => {
  const [value, setValue] = useState('');
  const searchRef = useRef(null);
  const translateMessage = Localization.useTranslateMessage();

  const debouncedSearch = debounce(() => {
    onSearchChange(normalizeText(value));
  }, 200);

  useEffect(() => {
    debouncedSearch();
    return () => {
      debouncedSearch.cancel();
    };
  }, [value, debouncedSearch]);

  const handleInputChange = useCallback(
    e => {
      const inputValue = e.target?.value;
      if (inputValue.length <= 50) {
        setValue(inputValue);
      }
    },
    [setValue]
  );

  const handleInputClear = useCallback(() => setValue(''), [setValue]);

  return (
    <SearchBarStyled
      inputRef={searchRef}
      variant="outlined"
      placeholder={translateMessage('addDashboardDialog_widgetTab_search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search onClick={() => searchRef.current.focus()} sx={{ fontSize: 24 }} />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position="end">
            <Close onClick={handleInputClear} sx={{ fontSize: 24, cursor: 'pointer' }} />
          </InputAdornment>
        ),
      }}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          searchRef.current.blur();
        }
      }}
      className="search-filter"
      disabled={disabled}
      onChange={handleInputChange}
      value={value}
    />
  );
};

export default SearchFilter;

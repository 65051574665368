import { css } from '@emotion/react';
import { alpha } from "@mui/material";

const pressedStyle = css`
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default ({ theme, pressed }) => css`
  width: 40px;
  height: 40px;
  padding: 10px;
  color: ${theme.validateMode({
    light: theme.colorsCA.darkBlue[10],
    dark: theme.colorsCA.blue[90]
  })};
  background: ${theme.validateMode({
    light: theme.colorsCA.neutral[100],
    dark: theme.colorsCA.neutral[10]
  })};

  &:hover {
    background: ${theme.validateMode({
      light: alpha(theme.colorsCA.darkBlue[10], 0.05),
      dark: alpha(theme.colorsCA.blue[70], 0.05),
    })};
  }

  ${pressed ? pressedStyle : ''}

  svg {
    margin-top: 2px;
  }

  &.loading {
    background-color: ${theme.validateMode({
      light: theme.colorsCA.blue[95],
      dark: theme.colorsCA.darkBlue[60]
    })};

    svg {
      animation: heartbeat 1s infinite;
    }
  }

  svg.circle-pulse {
    margin-top: 0px;
  }
`;

import { Localization } from 'connex-cds';
import { ConfirmationDialog } from '../../../../commons';
import styled from '@emotion/styled';

import styles from './styles';

const Styled = styled(ConfirmationDialog)`
  ${styles}
`;

const DeleteDashboardDialog = ({ open, onClose, onConfirm, dashboard }) => {
  return (
    <Styled open={open} onConfirm={onConfirm} onClose={onClose} confirmText="delete" color="delete">
      <h3 className="delete-dashboard-title">
        <Localization.Translate
          stringId="deleteDashboardDialog_title"
          values={{
            strong: chunks => <strong>{chunks}</strong>,
            name: dashboard?.name,
          }}
        />
      </h3>
      <p className="delete-dashboard-description">
        <Localization.Translate stringId="deleteDashboardDialog_description" />
      </p>
    </Styled>
  );
};

export default DeleteDashboardDialog;

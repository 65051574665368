import React from 'react';
import { any, func, string, bool, shape, array } from 'prop-types';
import { Localization } from 'connex-cds';
import { MenuItem, TextField, InputAdornment } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import { omit } from 'lodash';

import { withFormikField } from '../../hoc';
import { FormTooltip } from '..';

import styles from './styles';

const Styled = styled(TextField)`
  ${styles}
`;

const Select = ({
  name,
  value,
  disabled,
  testId,
  onChange,
  isSubmitting,
  labelStringId,
  errors,
  options,
  infoTooltipStringId,
  setFieldTouched,
  touched,
  ...otherProps
}) => {
  const translateMessage = Localization.useTranslateMessage();

  const handleOnChange = e => {
    onChange(name, e.target.value);
  };

  const hasError = !!errors[name] && touched[name];
  const errorText = hasError ? errors[name] : '';

  return (
    <Styled
      select
      name={name}
      value={value}
      data-testid={testId}
      label={translateMessage(labelStringId)}
      error={hasError}
      helperText={translateMessage(errorText)}
      disabled={disabled || isSubmitting}
      onChange={handleOnChange}
      InputProps={{
        endAdornment: infoTooltipStringId && (
          <FormTooltip
            arrow
            title={translateMessage(infoTooltipStringId)}
            placement="top-start"
            PopperProps={{ disablePortal: true }}
          >
            <InputAdornment position="end">
              <InfoOutlined />
            </InputAdornment>
          </FormTooltip>
        ),
      }}
      {...omit(otherProps, ['setFieldTouched', 'setFieldValue'])}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option.label}
        </MenuItem>
      ))}
    </Styled>
  );
};

Select.propTypes = {
  errors: shape({}).isRequired,
  disabled: bool,
  infoTooltipStringId: string,
  isSubmitting: bool,
  labelStringId: string.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: array.isRequired,
  testId: string.isRequired,
  value: any.isRequired,
  setFieldTouched: func.isRequired,
  touched: shape({}).isRequired,
};

Select.defaultProps = {
  disabled: false,
  infoTooltipStringId: '',
  options: [],
  isSubmitting: false,
};

export default withFormikField(Select);

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import dashboards from '../queries/dashboards';

export const useListDashboards = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['dashboards', entityRef],
    queryFn: () => dashboards.listDashboards({ entityRef }),
    staleTime: Infinity,
    enabled: !!entityRef,
  });
};

export const useGetDashboard = dashboardRef => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['dashboards', entityRef],
    queryFn: () => dashboards.getDashboard({ entityRef, dashboardRef }),
    staleTime: Infinity,
    enabled: !!entityRef || !dashboardRef,
  });
};

export const useGetDefaultDashboard = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['dashboards', entityRef],
    queryFn: () => dashboards.getDefaultDashboard({ entityRef }),
    staleTime: Infinity,
    enabled: !!entityRef,
  });
};

export const useCreateDashboard = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['dashboards', entityRef];

  const mutation = useMutation({
    mutationFn: ({ dashboard }) => dashboards.createDashboard({ entityRef, dashboard }),
    onError: (_err, _response, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};

export const useUpdateDashboard = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['dashboards', entityRef];
  const mutation = useMutation({
    mutationFn: ({ dashboard }) => dashboards.updateDashboard({ entityRef, dashboard }),
    onError: (_err, _response, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};

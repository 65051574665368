import React from 'react';
import styled from '@emotion/styled';
import { GridColumnMenu } from '@mui/x-data-grid-pro';
import ColumnMenuStyles from './style';

const Styled = styled(GridColumnMenu)`
  ${ColumnMenuStyles}
`;

const TableColumnMenu = props => {
  return (
    <Styled
      {...props}
      slots={{
        columnMenuPinningItem: null,
      }}
    />
  );
};

export { TableColumnMenu };

import React from 'react';
import { Popper } from '@mui/material';
import styled from '@emotion/styled';
import TablePoperStyles from './style';

const Styled = styled(Popper)`
  ${TablePoperStyles}
`;

const TablePopper = props => {
  return <Styled {...props} />;
};

export { TablePopper };

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Dialog from '@mui/material/Dialog';

export const DialogStyled = styled(Dialog)`
  ${({ theme }) => css`
    font-family: 'Work Sans';
    display: flex;
    justify-content: center;
    align-content: center;

    .MuiDialog-paper {
      height: 860px;
      max-height: 80%;
      width: 960px;
    }

    .add-dashboard-dialog-tabs {
      border-top: 1px solid ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.12)', dark: '#938F99' })};
      flex-shrink: 0;
    }

    .MuiTab-root.MuiTab-labelIcon {
      font-size: 14px;

      .MuiTab-iconWrapper {
        font-size: 24px;
      }
    }

    .MuiButtonBase-root.MuiTab-root.Mui-selected {
        color: ${theme.validateMode({ light: theme.colorsCA.blue[60], dark: theme.colorsCA.blue[90] })};
        border-bottom: 2px solid ${theme.validateMode({ light: theme.colorsCA.blue[60], dark: theme.colorsCA.blue[90] })};
      }
    }

    .MuiDialogContent-root,
    .dialog-content { 
      padding: 0;
    }

    .dialog-title {
      gap: 10px;
      font-size: 28px;

      .add-icon {
        font-size: 38px;
      }
    }

    .dialog-actions {
      display: flex;
      justify-content: flex-end;
      column-gap: 1em;
      align-items: center;
      padding: 16px 20px 20px;

      .first-action-column {
        display: flex;
        gap: 22px;
      }

      .second-action-column {
        display: flex;
        gap: 22px;
      }

      button {
        font-size: 16px;
        padding: 14px 24px;
        line-height: 20px;

        .step-control {
          ${theme.validateMode({ dark: `color: ${theme.colorsCA.blue[80]};` })}
        }
      }
    }

    .dialog-content {
      display: flex;
      padding: 0;
      overflow: hidden;

      ${theme.validateMode({ dark: 'border-top: 1px solid #938F99;' })}
      ${theme.validateMode({ dark: 'border-bottom: 1px solid #938F99;' })}
    }

    .stepper {
      overflow: hidden;
      width: 30ch;
      padding: 40px 45px 0 40px;
      border-right: 1px solid ${theme.validateMode({ light: 'rgba(0, 0, 0, 0.12)', dark: '#938F99' })};
    }

    .tab-content {
      flex: 1;
      padding: 16px;
      overflow: auto;
      display: flex;
      justify-content: center;
    }
  `}
`;

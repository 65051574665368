import da from './da';
import de from './de';
import enUS from './en-US';
import esCO from './es-CO';
import fr from './fr-FR';
import nlNL from './nl-NL';
import noNO from './no-NO';
import svSE from './sv-SE';

export default {
  da,
  de,
  'en-US': enUS,
  'en-GB': enUS,
  'es-CO': esCO,
  'fr-FR': fr,
  'nl-NL': nlNL,
  'no-NO': noNO,
  'sv-SE': svSE,
};

import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Localization } from 'connex-cds';
import widgetQueries from '../../../../../../api/queries/widget-queries';
import Table from '../../../Table';
import styles from './styles';

const Styled = styled.div`
  ${styles}
`;
const DEFAULT_REFRESH_INTERVAL = 120000;

const BatchVarianceByMaterialAndLocation = ({ widget, refreshSubscribe, onKebabAction }) => {
  const { entityRef } = useParams();
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [items, setItems] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(widget?.refreshInterval || DEFAULT_REFRESH_INTERVAL);
  const translateMessage = Localization.useTranslateMessage();

  const columns = [
    {
      field: 'Location',
      headerName: translateMessage('gridHeader_location'),
      headerAlign: 'left',
      align: 'left',
      type: 'number',
      flex: 1,
    },
    {
      field: 'Material',
      headerName: translateMessage('gridHeader_material'),
      headerAlign: 'left',
      align: 'left',
      type: 'string',
      flex: 1,
    },
    {
      field: 'Target',
      headerName: translateMessage('gridHeader_target'),
      headerAlign: 'left',
      flex: 1,
      type: 'number',
    },
    {
      field: 'Actual',
      headerName: translateMessage('gridHeader_actual'),
      headerAlign: 'left',
      flex: 1,
      type: 'number',
    },
    {
      field: 'Total Variance',
      headerName: translateMessage('gridHeader_totalVariance'),
      headerAlign: 'left',
      flex: 1,
      type: 'number',
    },
    {
      field: 'Avg Variance',
      headerName: translateMessage('gridHeader_avgVariance'),
      headerAlign: 'left',
      flex: 1,
      type: 'number',
    },
    {
      field: 'Loads',
      headerName: translateMessage('gridHeader_loads'),
      headerAlign: 'right',
      flex: 1,
      type: 'number',
    },
  ];

  const getItems = useCallback(
    async (dateOption = 'Today') => {
      const queryName = widget.queryIdentifier;
      const payload = {
        dateOption,
      };
      try {
        const result = await widgetQueries.createQuery({ entityRef, queryName, payload });
        setItems(
          result?.items.map((item, index) => {
            return { crn: index, ...item };
          })
        );
      } catch (error) {
        console.log(error);
      }
    },
    [widget]
  );

  const getWidgetData = useCallback(async () => {
    setLoading(true);
    await getItems();
    setLoading(false);
    setInitialLoad(false);
  }, [getItems]);

  useEffect(() => {
    getWidgetData();
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(getWidgetData, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval, getWidgetData]);

  useEffect(() => {
    const unsubscribe = refreshSubscribe(getWidgetData);
    return () => unsubscribe();
  }, [refreshSubscribe]);

  return (
    items && (
      <Styled>
        <div className="widget-header">{widget.publish?.name || widget.name}</div>
        <Table columns={columns} data={items} activeColumns={{}} />
      </Styled>
    )
  );
};

export default BatchVarianceByMaterialAndLocation;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ActionBarContainerStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    border-top: ${theme.validateMode({ light: '2px solid #e1e1e1', dark: '2px solid rgba(255, 255, 255, 0.12)' })};
    padding: 15px;

    .MuiButtonBase-root {
      color: #fff;
      border-radius: 100px;
      background: ${theme.colorsCA.darkBlue[50]};

      :hover {
        background: ${theme.colorsCA.darkBlue[50]};
      }
    }
  `}
`;

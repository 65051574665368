import { HeaderAndSidebar, useDefaultRoute } from 'connex-cds';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { RedirectToEntity } from './views/authenticated-routes/RedirectToEntity';
import Dashboard from './views/Dashboard';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<HeaderAndSidebar />}>
        <Route path="" element={<AuthenticatedRoutes />}>
          <Route path="" element={<RedirectToEntity />}>
            <Route path=":entityRef/*">
              <Route path="dashboards" element={<Dashboard />}>
                <Route path=":dashboardRef" element={<Dashboard />} />
              </Route>
              <Route path="*" element={<Navigate to={'dashboards/default'} />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

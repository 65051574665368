import menuLogo from './assets/menu-logo.svg';

const config = {
  menuLogo,
  sidebarOptions: [
    {
      id: 'dashboards',
      labelStringId: 'dashboards',
      path: '/dashboards',
      iconName: 'DashboardOutlined',
      defaultFeature: true,
    },
  ],
};

export const useMenuConfig = () => {
  return config;
};

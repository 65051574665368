import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Lottie from 'react-lottie';
import IconButton from '@mui/material/IconButton';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import * as circlePulseAnimationData from './circle-pulse.json';

import style from './style';

const Styled = styled(IconButton, { shouldForwardProp: prop => prop !== 'pressed' })`
  ${style}
`;

const FavoriteButton = ({ isFavorite = false, isLoading = true, onClick }) => {
  const defaultLottieOptions = {
    loop: true,
    autoplay: true, 
    animationData: circlePulseAnimationData,
    rendererSettings: {
      className: 'circle-pulse',
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Styled variant="outlined" onClick={onClick} className={isLoading ? 'loading' : ''} pressed={isFavorite}>
      {
        isLoading ? (
          <>
            <FavoriteOutlinedIcon sx={{
              "@keyframes heartbeat": {
                "0%": { transform: "scale(1)", opacity: 0.75 },
                "50%": { transform: "scale(1.25)", opacity: 1 },
                "100%": { transform: "scale(1)", opacity: 0.75 },
              },
            }} fontSize="medium" />
            <Lottie
              className="circle-pulse"
              options={defaultLottieOptions}
              height={80}
              width={80}
              style={{
                position: 'absolute'
              }}
            />
          </>
        ) : (
          isFavorite ? (
            <FavoriteOutlinedIcon fontSize="medium" />
          ) : (
            <FavoriteBorderOutlinedIcon fontSize="medium" />
          )
        )
      }
    </Styled>
  );
};

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};

export default FavoriteButton;

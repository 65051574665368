import React, { useState } from 'react';
import { Localization } from 'connex-cds';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import SelectableMenu from '../../../../commons/SelectableMenu';

const datePickerOptions = [
  {
    value: 'Today',
    label: 'datePicker_today',
  },
  {
    value: 'Yesterday',
    label: 'datePicker_yesterday',
  },
  {
    value: '3',
    label: 'datePicker_last_7_days',
  },
  {
    value: '4',
    label: 'datePicker_this_month',
  },
  {
    value: '5',
    label: 'datePicker_custom',
  },
];

const DateSelector = ({ handleShowCalendar }) => {
  const [value, setValue] = useState(datePickerOptions[0]);

  const handleOnChange = option => {
    handleShowCalendar(option.value === '5' || false);
    setValue(option);
  };

  return (
    <SelectableMenu
      value={value}
      onChange={handleOnChange}
      valueStartIcon={<CalendarMonthIcon fontSize="inherit" />}
      options={datePickerOptions}
      renderOption={({ label }) => <Localization.Translate stringId={label} />}
      withArrow
      withCheckMark
    />
  );
};

export default DateSelector;

import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,

  button: 'Button Text',
  save: 'Save',
  text: 'ISO 3166-1 (Codes for the representation of names of countries and their subdivisions – Part 1: Country codes) is a standard defining codes for the names of countries, dependent territories, and special areas of geographical interest. It is the first part of the ISO 3166 standard published by the International Organization for Standardization.',

  username: 'Username',
  password: 'Password',
  login: 'Login',
  required: 'Required',

  light: 'Light',
  dark: 'Dark',

  rememberMe: 'Remember Me',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Privacy Policy',

  customer: 'Customer',
  project: 'Project',

  isBillable: 'Billable',
  dispatchDateTime: 'Date',
  orderId: 'Order',
  ticketId: 'Ticket',
  location: 'Location',
  vehicleTypeName: 'Vehicle',
  product: 'Product',
  salesTax: 'Sales Tax',
  totalPrice: 'Total',
  extendedPrice: 'Ext',
  destination: 'Destination',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Vehicle Type ID',
  locationId: 'Location ID',
  customerId: 'Customer ID',
  projectId: 'Project ID',
  dispatchTime: 'Time',

  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',

  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,
  addWidget: 'Add Widgets',
  dashboards: 'Dashboards',
  createDashboard: 'Create Dashboard',
  addWidgetInstructions: 'Create or select another Dashboard',
  addWidgetEmptyStateSpotlight: 'Nothing here yet',
  columnFilters: 'Column Filters',
  newOrder: 'New Order',
  visible: 'Visible',
  hidden: 'Hidden',

  topSection: 'Top Section',

  itemDetail: 'Item Detail',

  quantity: 'Quantity',

  unloadingMethod: 'Unloading Method',

  time: 'Time',
  eventStatus: 'Status',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  mixIdLabel: 'Mix ID/Description',
  orderedQtyLabel: 'Ordered QTY',
  loadedQtyLabel: 'Loaded QTY',

  END_UNLOADING: 'Finish Unload',
  IN_YARD: 'In Yard',
  TO_JOB: 'Left Plant',
  LEAVE_JOB: 'Leave Job',
  CREATED: 'Ticketed',
  UNLOADING: 'Start Unload',
  ARRIVE_JOB: 'Arrive Site',

  plantName: 'Plant Name',
  plantAddress: 'Plant Address',
  loadingScheduleStart: 'Loading Scheduled Start Date and Time',

  deliveryAddress: 'Delivery Address',
  phoneNumber: 'Phone Number',
  unloadingScheduleStart: 'Unloading Scheduled Start Date and Time',
  startToFinishUnloadingTime: 'Unloading Start - Finish Date and Time',

  deliveryInformation: 'Delivery Information',
  next: 'Next',

  material: 'Material',
  loadingPoint: 'Loading Point',
  unloadingPoint: 'Unloading Point',

  camera: 'Camera',
  comment: 'Comment',

  sapDeliveryNumber: 'SAP Delivery number (BCC Number)',
  salesOrderNumber: 'Sales order number and item line',
  wcDateTime: 'Date and time W/C contact',
  po: 'PO Number',
  orderedByName: 'Order placed by name',
  orderedByPhone: 'Order placed by phone number',
  resourceId: 'Resource ID',
  totalLoadedQty: 'Total loaded qty including this load',
  spacing: 'Load space',
  deliveryFlow: 'Delivery flow',
  timeOnSite: 'Total on site time/ordered unloading time',
  salesText: 'Sales text (material ID)',
  strengthClass: 'Strength class',
  environmentExposure: 'Environment exposure',
  metExposureClasses: 'Met exposure classes',
  dmax: 'Dmax',
  standardCert: 'Standard (certification)',
  workabilityTarget: 'Customer workability target',
  stoneQuality: 'Stone Quality',
  sandQuality: 'Sand Quality',
  targetWC: 'Target W/C ratio from recipe',
  controlClass: 'Control Class',
  chlorideClass: 'Chloride Class',
  typeAndStrength1: 'Type of cement and strengthclass 1',
  typeAndStrength2: 'Type of cement and strengthclass 2',
  typeOfAddition: 'Type of addition',
  typeOfAdmixture: 'Type of admixture',
  orderedTemp: 'Ordered Temperature',
  fibers1: 'Type and content of fibers 1',
  fibers2: 'Type and content of fibers 2',
  calculatedMaturityTime: 'Calculated maturity time from W/C contact time',

  generalDelivery: 'General Delivery Information',
  materialInfo: 'Material Information',

  cardTitle: 'Card Title',
  cardSubtitle: 'Card Subtitle',
  cardBody: 'Card Body',

  alertTitle: 'The Old Man and the Sea',
  alertMessage:
    'He was an old man who fished alone in a skiff in the Gulf Stream and he had gone eighty-four days now without taking a fish.',

  water: 'Water',
  materialService: 'Material / Service',
  returnedMaterial: 'Returned Material',

  waterAdded: 'Water Added',
  concreteOnTruck: 'Concrete on Truck',
  reason: 'Reason',
  customerAddedWater: 'Customer Added Water',
  addWater: 'Add Water',
  addedBy: 'Added By',

  addMaterialService: 'Add Material/Service',
  customerAddedProduct: 'Customer Added Product',

  addReturnedMaterial: 'Add Returned Material',
  leftover: 'Leftover',

  submitAcceptance: 'Submit Acceptance',
  customerAcceptance: 'Customer Acceptance',

  materialSummary: 'Material Summary',
  mix: 'Mix',
  ordered: 'Ordered',
  loaded: 'Loaded',

  driverComment: 'Driver Comment',
  customerComment: 'Customer Comment',
  editComment: 'Edit Comment',
  addComment: 'Add Comment',
  additionalInfo: 'Additional Info',
  onBoard: 'On Board',
  driver: 'Driver',
  commentInstruction: 'Comment Instruction',
  signatureInstruction: 'Signature Instruction',
  customerSignature: 'Customer Signature',

  general: 'General',
  tabTwoStringId: 'Tab Two',
  tabThreeStringId: 'Tab Three',

  status: 'Status',
  updateStatusTime: 'Update Status Time',
  delete: 'Delete',
  cancel: 'Cancel',
  back: 'Back',
  add: 'Add',

  id: 'ID',
  name: 'Name',
  invoiceable: 'Invoiceable',
  provideSignature: 'Provide Signature',
  ticket: 'Ticket',
  details: 'Details',
  qrCode: 'QR',

  driverAcceptanceReason: 'Reason',
  driverAcceptance: 'Driver Acceptance',

  'payment-method': 'Payment Methods',
  'add-payment-method': 'Add Payment Method',

  'cancel-reason-code': 'Cancel Reasons',
  'add-cancel-reason-code': 'Add Reason',

  users: 'Users',

  dashboard: 'Dashboard',

  dashboardSelector_default: 'Default Dashboard',
  dashboardSelector_action_share: 'Share Dashboard',
  dashboardSelector_action_edit: 'Edit Dashboard',
  dashboardSelector_action_duplicate: 'Duplicate Dashboard',
  dashboardSelector_action_delete: 'Delete Dashboard',

  createMenu: 'Create',
  createMenu_widget: 'New Widget',
  createMenu_dashboard: 'New Dashboard',
  createMenu_customer: 'New Customer',
  createMenu_project: 'New Project',
  createMenu_contact: 'New Contact',

  datePicker_today: 'Today',
  datePicker_yesterday: 'Yesterday',
  datePicker_last_7_days: 'Last 7 days',
  datePicker_this_month: 'This Month',
  datePicker_custom: 'Custom',
  datePicker_start: 'Select start range',
  datePicker_end: 'Select end range',

  addDashboardDialog_tabs_dashboardInfo: 'Information',
  general_widget: 'Widgets',
  addDashboardDialog_dialogAction_save: 'Save',
  addDashboardDialog_dialogAction_next: 'Next',
  addDashboardDialog_dialogAction_back: 'Back',
  addDashboardDialog_dashboardInfoTab_title: 'Add dashboard title',
  addDashboardDialog_widgetTab_title: 'Add Widget(s):',
  addDashboardDialog_widgetTab_search: 'Search Widgets',
  addDashboardDialog_widgetTab_widget_count: 'Widget Count',

  addWidgetDialog_dialogAction_save: 'Save',
  addWidgetDialog_dialogAction_next: 'Next',
  addWidgetDialog_dialogAction_back: 'Back',
  addWidgetDialog_widgetTab_title: 'Add Widget(s):',
  addWidgetDialog_widgetTab_search: 'Search Widgets',
  addWidgetDialog_widgetTab_widget_count: 'Widget Count',

  dashboards_editDashboard_title: 'Edit Dashboard',
  dashboards_addDashboard_title: 'Create Dashboard',
  dashboards_duplicateDashboard_title: 'Duplicate Dashboard',
  dashboards_addWidget_title: 'Add Widget',
  addDashboardDialog_dashboardInfoTab_description: 'Add dashboard description',
  addDashboardDialog_dashboardInfoTab_name: 'Name',
  addDashboardDialog_dashboardInfoTab_defaultWidgets: 'Add Default Widgets?',
  addDashboardDialog_defaultWidgets_tooltip: 'A default set of widgets will be added when this dashboard is created.',
  notification_changesSaved: 'Changes saved',
  dashboardNotification_dashboardAdded: 'New dashboard added',
  dashboardNotification_dashboardDuplicated: 'Dashboard duplicated',
  notification_dashboardSaved: 'Dashboard changes saved',
  deleteDashboardDialog_title: 'Delete "{name}"?',
  deleteDashboardDialog_description: 'This action will permanently remove this Dashboard from your Dashboard menu',
  notification_dashboardDeleted: 'Dashboard successfully deleted',
  notification_newWidgetsAdded: 'New Widgets added',

  selectCompany: 'Select Company',

  tickets: 'Tickets',
  widgetComparisonFromYesterday: 'From Yesterday',

  //==== GRID raw material requirements ====
  gridHeader_location_id: 'Location ID',
  gridHeader_material_id: 'Material ID',
  gridHeader_material_name: 'Material Name',
  gridHeader_quantity: 'Quantity',
  gridHeader_uom: 'UOM',

  gridHeaderMenu_menu_label: 'Menu',
  gridHeaderMenu_unsort_label: 'Unsort',
  gridHeaderMenu_asc_label: 'Sort by ASC',
  gridHeaderMenu_desc_label: 'Sort by DESC',
  gridHeaderMenu_sort_label: 'Sort',
  gridHeaderMenu_hide_column_label: 'Hide column',
  gridHeaderMenu_manage_columns_label: 'Manage columns',
  gridHeaderMenu_find_column_label: 'Find column',
  gridHeaderMenu_search_column_label: 'Column title',
  gridHeaderMenu_show_all_label: 'Show all',
  gridHeaderMenu_hide_all_label: 'Hide all',
  gridHeaderMenu_show_columns_label: 'Columns',

  //==== GRID cod recap by payment method ====
  gridHeader_plant: 'Plant',
  gridHeader_method: 'Method',
  gridHeader_total: 'Total',

  //==== GRID cod recap by order ====
  gridHeader_order: 'Order',
  gridHeader_customer: 'Customer',
  gridHeader_project: 'Project',
  gridHeader_po: 'PO',
  gridHeader_payment_method: 'Payment Method',

  gridFooter_rowCount_prefix: 'Displaying',
  gridFooter_rowCount_suffix: 'records',

  ticketStatus_tickets: 'Tickets',

  //==== bar chart widget
  ticketStatus_received: 'Received',
  ticketStatus_pending: 'Pending',
  ticketStatus_rejected: 'Rejected',
  ticketStatus_accepted: 'Accepted',

  //==== GRID location production statistics
  gridHeader_loads: 'Loads',
  gridHeader_avgLoad: 'Avg Load',
  gridHeader_avgBatchMinutes: 'Avg Batch Minutes',
  gridHeader_avgInYardMinutes: 'Avg In Yard Minutes',
  gridHeader_qtyPerHour: 'Qty per Hour',

  gridHeader_product: 'Product',
  gridHeader_description: 'Description',
  gridHeader_avgBatchTime: 'Avg Batch Time',

  gridHeader_location: 'Location',
  gridHeader_material: 'Material',
  gridHeader_target: 'Target',
  gridHeader_actual: 'Actual',
  gridHeader_totalVariance: 'Total Variance',
  gridHeader_avgVariance: 'Avg Variance',

  gridHeader_orderDate: 'Order Date',
  gridHeader_orderNumber: 'Order Number',
  gridHeader_orderStatus: 'Order Status',
  gridHeader_productName: 'Product Name',
  gridHeader_unitPrice: 'Unit Price',

  gridHeader_orders: 'Orders',
  gridHeader_orderQuantity: 'Ordered Quantity',
  gridHeader_shippedQuantity: 'Shipped Quantity',
  gridHeader_cancelledQuantity: 'Cancelled Quantity',
  gridHeader_excessiveUnloadingMinutes: 'Excessive Unloading',

  errors,
  themes,
};

import { useState, useMemo } from 'react';
import { Localization } from 'connex-cds';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { SearchFilter } from '../../../../commons';
import { getFilteredDataByProperties } from '../../../../commons/search-filter';
import { WidgetCard } from '../WidgetCard';
import styles from './styles';

const Styled = styled.div`
  ${styles}
`;

const WidgetTab = ({ widgetList, areWidgetsLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const filteredData = useMemo(
    () => getFilteredDataByProperties({ data: widgetList, query: searchTerm, properties: ['name', 'widgetId'] }),
    [widgetList, searchTerm]
  );

  return (
    <Styled className="widgets">
      <h3 className="widget-title">
        <Localization.Translate stringId="addDashboardDialog_widgetTab_title" />
      </h3>
      <SearchFilter onSearchChange={setSearchTerm} disabled={areWidgetsLoading} />
      <div className={classNames('widgets-form', `${filteredData?.length === 1 ? 'single-widget' : ''}`)}>
        {!areWidgetsLoading && (
          <div className="mainContent">
            <div className="Container">
              {filteredData?.map((widget, index) => (
                <WidgetCard key={index} widget={widget} index={index}></WidgetCard>
              ))}
            </div>
          </div>
        )}
      </div>
    </Styled>
  );
};

export default WidgetTab;

import { useState } from 'react';
import { Localization } from 'connex-cds';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from '@emotion/styled';

import Button from '../../../../commons/Button';

import { MenuStyle } from './style';

const Styled = styled(Menu)`
  ${MenuStyle}
`;

const CreateMenu = ({ onAddDashboard, onAddWidget }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddDashboard = () => {
    onAddDashboard();
    setAnchorEl(null);
  };

  const handleAddWidget = () => {
    onAddWidget();
    setAnchorEl(null);
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SelectButtonEndIcon = anchorEl ? (
    <ArrowDropUpIcon fontSize="inherit" />
  ) : (
    <ArrowDropDownIcon fontSize="inherit" />
  );

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<AddIcon fontSize="inherit" />}
        endIcon={SelectButtonEndIcon}
        pressed={!!anchorEl}
      >
        <Localization.Translate stringId="createMenu" />
      </Button>
      <Styled className="create-menu" anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleAddWidget}>
          <AddIcon />
          <Localization.Translate stringId="createMenu_widget" />
        </MenuItem>
        <MenuItem onClick={handleAddDashboard}>
          <AddIcon />
          <Localization.Translate stringId="createMenu_dashboard" />
        </MenuItem>
        <MenuItem>
          <AddIcon />
          <Localization.Translate stringId="createMenu_customer" />
        </MenuItem>
        <MenuItem>
          <AddIcon />
          <Localization.Translate stringId="createMenu_project" />
        </MenuItem>
        <MenuItem>
          <AddIcon />
          <Localization.Translate stringId="createMenu_contact" />
        </MenuItem>
      </Styled>
    </>
  );
};

export default CreateMenu;

import React from 'react';
import { useUserContext } from '../../components/user';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { useAppData } from '../app-data/AppData';
import { usePostMessageListener } from '../entity-provider/usePostMessageListener';
import { getPermittedMenuConfig } from '../getPermittedMenuConfig';
import { AppContext } from './context';

const reducer = (state, action) =>
  Object.keys(action).reduce(
    (acc, key) => {
      acc[key] = action[key];
      return acc;
    },
    { ...state }
  );

const AppContextProvider = ({ children }) => {
  const [currentFeature, setCurrentFeature] = React.useState();
  const [state, dispatch] = React.useReducer(reducer, {});
  const { appRef, appConfig } = state;

  const isEntityPickerRequired = React.useMemo(() => {
    return appRef && appConfig?.isEntitySpecific;
  }, [appConfig, appRef]);

  const appConfigListener = React.useCallback(message => {
    const { type, ...rest } = message;
    if (type === 'app-config') {
      dispatch({ appRef: message?.appConfig?.crn, ...rest });
    }
  }, []);

  usePostMessageListener(appConfigListener);

  const { sendMessage } = usePostMessageContext();

  React.useEffect(() => {
    sendMessage({ type: 'req-app-config' });
  }, [sendMessage]);

  const setAppEntities = React.useCallback(value => {
    dispatch({ appEntities: value });
  }, []);

  const setHeaderText = React.useCallback(value => {
    dispatch({ headerText: value });
  }, []);

  const { menuConfig } = useAppData();
  const userContext = useUserContext();
  const permittedMenuConfig = React.useMemo(() => {
    // Temporary workaround to support both menuConfig shapes until everyone has changed to the new format.
    return getPermittedMenuConfig({ menuConfig: menuConfig.sidebarOptions || menuConfig, userContext });
  }, [menuConfig, userContext]);

  return (
    <AppContext.Provider
      value={{
        setAppEntities,
        isEntityPickerRequired,
        menuConfig: permittedMenuConfig,
        currentFeature,
        setCurrentFeature,
        setHeaderText,
        ...state,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

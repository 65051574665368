import { css } from '@emotion/react';

const pressedStyle = ({ theme }) => css`
  background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};
  color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
  box-shadow: ${theme.validateMode({
    light: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    dark: '0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
  })};
  filter: ${theme.validateMode({
    light: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))',
    dark: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3))',
  })};
`;

const defaultActiveStyle = ({ theme }) => css`
  background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};
  color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
`;

export const ButtonStyle = ({ theme, defaultActive, pressed }) => css`
  display: flex;
  gap: ${theme.spacing(2)};
  justify-content: center;
  align-items: center;
  line-height: 20px;
  font-size: 16px;
  border-radius: 100px;
  background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};
  padding: 10px 24px;
  font-weight: 500;
  box-sizing: border-box;
  height: 40px;

  &:hover {
    background: ${theme.validateMode({
      light: 'linear-gradient(0deg, rgba(0, 43, 84, 0.05) 0%, rgba(0, 43, 84, 0.05) 100%)',
      dark: 'linear-gradient(0deg, rgba(85, 187, 233, 0.05) 0%, rgba(85, 187, 233, 0.05) 100%)',
    })};
  }

  ${defaultActive ? defaultActiveStyle({ theme }) : ''}

  ${pressed ? pressedStyle({ theme }) : ''}
`;



// color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
// border: 1px solid ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
// background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};

// &:hover {
//   background: ${theme.validateMode({
//     light: 'linear-gradient(0deg, rgba(0, 43, 84, 0.05) 0%, rgba(0, 43, 84, 0.05) 100%)',
//     dark: 'linear-gradient(0deg, rgba(85, 187, 233, 0.05) 0%, rgba(85, 187, 233, 0.05) 100%)',
//   })};
// }
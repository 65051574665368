export const normalizeText = query =>
  query
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .trim()
    .toLowerCase();

export const getFilteredDataByProperties = ({ query, data = [], properties = [] }) => {
  if (query && query.length) {
    const normalizedQuery = normalizeText(query);
    return data.filter(item =>
      properties.some(
        property =>
          item.hasOwnProperty(property) &&
          item[property] !== null &&
          typeof item[property] !== 'undefined' &&
          normalizeText(item[property]).includes(normalizedQuery)
      )
    );
  }

  return !data || !data?.length ? [] : data;
};

import * as yup from 'yup';

const dashboardInfoValidationSchema = yup.object().shape({
  name: yup.string().trim().required('required'),
});

export const stepValidationSchemas = [dashboardInfoValidationSchema];

export const validationSchema = stepValidationSchemas.reduce(
  (composedSchema, stepSchema) => composedSchema.concat(stepSchema),
  yup.object().shape({})
);

export const defaultValues = { name: '', defaultWidgets: '' };

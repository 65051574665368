import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';

import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import style from './style';

const Styled = styled(IconButton)`
  ${style}
`;

const RefreshButton = ({ isRefreshing = false, onClick }) => {
  return (
    <Styled variant="outlined" onClick={onClick} className={isRefreshing ? 'refreshing' : ''}>
      <SyncOutlinedIcon sx={{
        "@keyframes spin": {
          "0%": { transform: "rotate(360deg)", },
          "100%": { transform: "rotate(0deg)", },
        },
      }} />
    </Styled>
  );
};

RefreshButton.propTypes = {
  isRefreshing: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RefreshButton;

import { useState, useMemo } from 'react';
import { pick } from 'lodash';
import { Localization, useNotification } from 'connex-cds';
import { Formik, useFormikContext } from 'formik';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import { defaultValues, validationSchema } from './form-config';
import DashboardInfoTab from '../dashboard-info-tab';
import { useCreateDashboard } from '../../../../api/hooks';
import { DialogTitle } from '../../../../commons';
import { DialogStyled } from './styles';

const DetailsDialog = ({ open = false, onClose, duplicateDashboard }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { mutateAsync: createDashboard } = useCreateDashboard();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { values, ...formikBag } = useFormikContext();
  const { isSubmitting, dirty, isValid } = formikBag;
  const DASHBOARD_TABS = [
    {
      stringId: 'addDashboardDialog_tabs_dashboardInfo',
      component: <DashboardInfoTab editDashboard={duplicateDashboard} />,
      icon: <DriveFileRenameOutlineRoundedIcon />,
    },
  ];

  const handleClose = response => {
    onClose(response);
    formikBag.resetForm();
    setCurrentTab(0);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const handleTabChange = (_, tabValue) => {
    setCurrentTab(tabValue);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleOnSubmit = async () => {
    const { name } = values;
    formikBag.setSubmitting(true);
    delete duplicateDashboard.crn;
    delete duplicateDashboard.defaultDashboard;

    const widgets = Object.values(duplicateDashboard.widgets)
      .flat(Infinity)
      .map(widget => {
        return pick(widget, ['widgetId', 'name', 'type', 'options', 'dataSource', 'queryIdentifier']);
      });

    let response;
    try {
      const dashboard = {
        ...duplicateDashboard,
        name: name,
        widgets: widgets,
      };
      response = await createDashboard({ dashboard });

      notification.success(translateMessage('dashboardNotification_dashboardDuplicated'));
    } catch (error) {
      console.error(error);
    }

    formikBag.setSubmitting(false);
    formikBag.resetForm();
    handleClose(response);
  };

  return (
    <DialogStyled className="duplicate-dashboard-dialog" open={open} maxWidth="lg" fullWidth onClose={handleCloseDialog}>
      <DialogTitle className="dialog-title" onClose={handleClose}>
        <ContentCopyOutlinedIcon className="duplicate-icon" />
        <Localization.Translate stringId="dashboards_duplicateDashboard_title" />
      </DialogTitle>
      <>
        <DialogContent className="dialog-content" dividers>
          <div className="tab-content">{DASHBOARD_TABS[currentTab].component}</div>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <div className="step-controls">
            {currentTab !== 0 && (
              <Button onClick={goToStep(currentTab - 1)}>
                <Localization.Translate stringId="addDashboardDialog_dialogAction_back" />
              </Button>
            )}
            {currentTab < DASHBOARD_TABS.length - 1 && (
              <Button onClick={goToStep(currentTab + 1)}>
                <Localization.Translate stringId="addDashboardDialog_dialogAction_next" />
              </Button>
            )}
          </div>
          <Button variant="contained" disabled={isSubmitting || !isValid} onClick={handleOnSubmit}>
            <Localization.Translate stringId="addDashboardDialog_dialogAction_save" />
          </Button>
        </DialogActions>
      </>
    </DialogStyled>
  );
};

export default ({ duplicateDashboard, ...props }) => {
  const initialValues = useMemo(() => {
    if (duplicateDashboard) {
      const { name } = duplicateDashboard;
      defaultValues.name = `${name} -- Copy`;
    } else {
      defaultValues.name = '';
    }

    return defaultValues;
  }, [duplicateDashboard]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      <DetailsDialog
        {...props}
        duplicateDashboard={duplicateDashboard}
      />
    </Formik>
  );
};

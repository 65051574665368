import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Localization } from 'connex-cds';
import widgetQueries from '../../../../../../api/queries/widget-queries';

import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import styles from './styles';
import { round } from 'lodash';

const Styled = styled.div`
  ${styles}
`;
const DEFAULT_REFRESH_INTERVAL = 120000;

const TicketCountByStatus = ({ widget, refreshSubscribe, onKebabAction }) => {
  const { entityRef } = useParams();
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentItems, setCurrentItems] = useState(false);
  const [previousItems, setPreviousItems] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(widget?.refreshInterval || DEFAULT_REFRESH_INTERVAL);

  const getItems = useCallback(async ({ dateOption = 'Today', startDate, endDate }) => {
    const queryName = widget.queryIdentifier;
    const payload = {
      dateOption,
      startDate,
      endDate
    };
    try {
      const result = await widgetQueries.createQuery({ entityRef, queryName, payload });
      return result?.items;
    } catch (error) {
      console.log(error);
    }
  });

  const getCurrentItems = useCallback(async () => {
    const items = await getItems({dateOption: 'Today'});
    if (items && items.length) { setCurrentItems(items[0]); }
  }, [entityRef, widget]);

  const getPreviousItems = useCallback(async () => {
    const items = await getItems({dateOption: 'Yesterday'});
    if (items && items.length) { setPreviousItems(items[0]); }
  }, [entityRef, widget]);
  
  const getWidgetData = useCallback(async () => {
    setLoading(true);
    await getCurrentItems();
    await getPreviousItems();
    setLoading(false);
    setInitialLoad(false);
  }, [getCurrentItems, getPreviousItems]);

  useEffect(() => {
    getWidgetData();
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(getWidgetData, refreshInterval);
      return () => clearInterval(interval);
    }

  }, [refreshInterval, getWidgetData]);
  
  useEffect(() => {
    const unsubscribe = refreshSubscribe(getWidgetData);
    return () => unsubscribe();
  }, [refreshSubscribe]);

  const handleKebabAction = () => {
    onKebabAction();
  };

  const selectedStatus = widget.options?.selectedOptions?.ticketStatus || 'pending';
  let currentTicketCount = 0;
  let previousTicketCount = 0;
  if (currentItems?.data) {
    currentTicketCount = currentItems?.data[selectedStatus.toUpperCase()] || 0;
  }
  if (previousItems?.data) {
    previousTicketCount = previousItems?.data[selectedStatus.toUpperCase()] || 0;
  }

  const ticketCountChange = currentTicketCount - previousTicketCount;
  const comparison = (((currentTicketCount - previousTicketCount) / previousTicketCount) * 100);
  const comparisonPercentage = currentTicketCount === 0 ? '0' : round(comparison, 0);
  const comparisonTitle = previousTicketCount + ' Tickets Yesterday';
  const previousPercentage = initialLoad ? '--' : `${comparisonPercentage}%`;
  let ticketCountIncrease = 'neutral';
  if (previousTicketCount === 0) {
    ticketCountIncrease = 'neutral';
  } else if (ticketCountChange > 0) {
    ticketCountIncrease = 'positive';
  } else if (Math.abs(comparisonPercentage) >= 25 && ticketCountChange < 0) {
    ticketCountIncrease = 'danger';
  } else if (ticketCountChange < 0) {
    ticketCountIncrease = 'negative';
  }

  return (
    <Styled>
      <header>
        <LocalActivityOutlinedIcon style={{ fontSize: 40 }} className={`widget-icon ${ticketCountIncrease}`} />
        <span className="title">{ widget.title || widget.name }</span>
        <MoreVertOutlinedIcon style={{ fontSize: 24 }} className="kebab-icon" />
      </header>
      <div className="widget-data">
        <span className="ticket-count">
          { initialLoad && loading ? 'Loading' : currentTicketCount } <Localization.Translate stringId="ticketStatus_tickets" />
        </span>
        <span className="selected-status"><Localization.Translate stringId={`ticketStatus_${selectedStatus}`} /></span>
      </div>
      <footer title={comparisonTitle} className={ticketCountIncrease}>
        <span className="ticket-count-comparison">
          { !initialLoad && previousTicketCount === 0 ? 'N/A' : previousPercentage }
        </span>
        <Localization.Translate stringId="widgetComparisonFromYesterday" />
      </footer>
    </Styled>
  );
};

export default TicketCountByStatus;

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem } from 'connex-cds';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { InitializeApi } from './api/InitializeApi';
import { AppRoutes } from './AppRoutes';
import messages from './i18n';
import { useMenuConfig } from './menu-config';
import ThemeProvider from './theme/ThemeProvider';

API.setSource('dashboards-exp');

const ONE_HOUR = 1000 * 60 * 60;
const TWELVE_HOURS = ONE_HOUR * 12;

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000, cacheTime: TWELVE_HOURS, refetchOnWindowFocus: false } },
});

const App = () => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);
  const menuConfig = useMenuConfig();

  React.useEffect(() => {
    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  return (
    <InitializeApi>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ConnexDesignSystem
            messages={messages}
            initialTimeZone="America/Chicago"
            appId="dashboards-exp"
            menuConfig={menuConfig}
            contentOnly={true}
          >
            <ThemeProvider>
              <AppRoutes />
            </ThemeProvider>
          </ConnexDesignSystem>
        </QueryClientProvider>
      </BrowserRouter>
    </InitializeApi>
  );
};

export default App;

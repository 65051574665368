import React, { useState, useEffect, useCallback } from 'react';
import { Localization, Layout, Typography, useNotification, useHeaderText } from 'connex-cds';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { orderBy } from 'lodash';

import Widget from './components/Widget';
import DashboardSelector from './components/DashboardSelector';
import EmptyDashboard from './components/EmptyDashboard';
import FavoriteButton from './components/FavoriteButton';
import RefreshButton from './components/RefreshButton';
import CreateMenu from './components/CreateMenu';
import DateSelector from './components/DateSelector';
import DateRangePicker from './components/DateRangePicker';
import ActionButton from '../../commons/action-button';
import dashboards from '../../api/queries/dashboards';
import { DashboardStyled, ContainerStyled } from './style';
import AddDashboardDialog from './components/add-dashboard-dialog';
import DuplicateDashboardDialog from './components/duplicate-dashboard-dialog';
import DeleteDashboardDialog from './components/delete-dashboard-dialog';
import AddWidgetDialog from './components/add-widget-dialog';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';

const Styled = styled(Layout.Column)`
  ${DashboardStyled}
`;

const Dashboard = () => {
  const navigate = useNavigate();
  const { entityRef, dashboardRef } = useParams();
  const loadingOption = { value: null, label: 'Loading...', translate: false };
  const [dashboard, setDashboard] = useState(false);
  const [widgets, setWidgets] = useState([]);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [selectedDashboardOption, setSelectedDashboardOption] = useState(loadingOption);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showEmptyDashboard, setShowEmptyDashboard] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [openAddDashboardDialog, setOpenAddDashboardDialog] = useState(false);
  const [openAddWidgetDialog, setOpenAddWidgetDialog] = useState(false);
  const [openDuplicateDashboardDialog, setOpenDuplicateDashboardDialog] = useState(false);
  const [editDashboard, setEditDashboard] = useState(null);
  const [duplicateDashboard, setDuplicateDashboard] = useState(null);
  const [deleteDashboard, setDeleteDashboard] = useState(null);
  const [refreshSubscriptions, setRefreshSubscriptions] = useState([]);
  const translateMessage = Localization.useTranslateMessage();
  const notification = useNotification();
  const { setHeaderText } = useHeaderText();

  const dashboardActions = [
    {
      id: 'dashboardSelector_action_share',
      icon: <FolderSharedIcon />,
      label: 'dashboardSelector_action_share',
      translate: true,
    },
    {
      id: 'dashboardSelector_action_edit',
      icon: <DriveFileRenameOutlineRoundedIcon />,
      label: 'dashboardSelector_action_edit',
      translate: true,
      onClick: async () => {
        handleEditDashboardClick();
      },
    },
    {
      id: 'dashboardSelector_action_duplicate',
      icon: <ContentCopyOutlinedIcon />,
      label: 'dashboardSelector_action_duplicate',
      translate: true,
      onClick: async () => {
        handleDuplicateDashboardClick();
      },
    },
    {
      id: 'dashboardSelector_action_delete',
      icon: <DeleteIcon />,
      label: 'dashboardSelector_action_delete',
      translate: true,
      onClick: async () => {
        setDashboardDelete();
      },
    },
  ];

  const updateDashboardOptions = useCallback(async () => {
    const response = await dashboards.listDashboards({ entityRef });
    const sortedOptions = orderBy(response, ['createDate'], ['asc']).map(dashboard => {
      return { translate: false, value: dashboard.crn, label: dashboard.name };
    });
    setDashboardOptions(sortedOptions);
    if (!sortedOptions || !sortedOptions.length) {
      setShowEmptyDashboard(true);
    }
  }, [dashboard, dashboards, dashboardOptions]);
  const updateSelectionToDefaultDashboard = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await dashboards.getDefaultDashboard({ entityRef });
      handleDashboardChange(response);
    } catch (error) {
      if (error?.status === 404) {
        setShowEmptyDashboard(true);
        setHeaderText(<Typography.H1>Dashboards</Typography.H1>);
      }
    }
    setIsLoading(false);
  }, []);

  const setDashboardDelete = useCallback(() => {
    setDeleteDashboard(dashboard);
  }, [dashboard]);
  const closeDeleteDashboardDialog = () => setDeleteDashboard(null);
  const handleDeleteDashboard = async () => {
    try {
      if (dashboard?.crn) {
        setIsLoading(true);
        const dashboardRef = dashboard?.crn;
        await dashboards.deleteDashboard({ entityRef, dashboardRef });
        setDashboardOptions(dashboardOptions.filter(option => option.value !== dashboardRef));
        notification.success(translateMessage('notification_dashboardDeleted'));
        await updateSelectionToDefaultDashboard();
      }
    } catch (error) {
      // TODO:: handle error flow
      console.error(error);
    }
  };

  const handleNewOrderClick = () => {};

  const handleAddDashboardClick = () => setOpenAddDashboardDialog(true);
  const handleCloseAddDashboardDialog = useCallback(response => {
    if (response?.crn) {
      setIsLoading(true);
      handleDashboardChange(response);
      listDashboards();
    }
    setOpenAddDashboardDialog(false);
    setEditDashboard(null);
  }, []);
  const handleEditDashboardClick = () => {
    setEditDashboard(dashboard);
    setOpenAddDashboardDialog(true);
  };
  const handleDuplicateDashboardClick = () => {
    setDuplicateDashboard(dashboard);
    setOpenDuplicateDashboardDialog(true);
  }
  const handleCloseDuplicateDashboardDialog = useCallback(response => {
    if (response?.crn) {
      setIsLoading(true);
      handleDashboardChange(response);
      listDashboards();
    }
    setOpenDuplicateDashboardDialog(false);
    setDuplicateDashboard(null);
  }, []);

  const handleAddWidgetClick = () => {
    setEditDashboard(dashboard);
    setOpenAddWidgetDialog(true);
  };
  const handleCloseAddWidgetDialog = useCallback(response => {
    if (response?.crn) {
      setIsLoading(true);
      handleDashboardChange(response);
      listDashboards();
    }
    setOpenAddWidgetDialog(false);
    setEditDashboard(null);
  }, []);

  const handleFavoriteDashboard = async () => {
    if (dashboard?.crn) {
      setIsFavoriting(true);
      const response = await dashboards.setDefaultDashboard({ entityRef, dashboardRef: dashboard.crn });
      setIsFavorite(prevState => !prevState);
      setIsFavoriting(false);
    }
  };
  const handleRefreshDashboard = async () => {
    setIsRefreshing(true);
    const promises = [];
    refreshSubscriptions.forEach((fn) => {
      promises.push(fn());
    });
    Promise.all(promises).finally(() => setIsRefreshing(false));
  };
  const handleDashboardChange = useCallback(newDashboard => {
    setDashboard(newDashboard);
    handleTitleChange(newDashboard);
    if (newDashboard?.crn) {
      navigate(`../dashboards/${newDashboard.crn}`);
      setShowEmptyDashboard(!!!newDashboard.crn);
    }
    setIsFavorite(newDashboard?.defaultDashboard);
    setIsLoading(false);
  }, []);
  const handleDashboardSelectorChange = useCallback(async option => {
    const dashboardRef = option.value;
    const dashboardResponse = await dashboards.getDashboard({ entityRef, dashboardRef });
    handleDashboardChange(dashboardResponse);
  }, []);
  const handleTitleChange = useCallback(selectedDashboard => {
    setHeaderText(<Typography.H1>{selectedDashboard?.name}</Typography.H1>);
  });

  const updateSelectedDashboard = useCallback(() => {
    if (dashboard?.crn) {
      setSelectedDashboardOption({ value: dashboard.crn, label: dashboard.name, translate: false });
    }
  }, [dashboard]);

  const handleRefreshSubscribe = useCallback((fn) => {
    setRefreshSubscriptions(s => [...s, fn]);
    return () => { setRefreshSubscriptions(s => s.filter(v => v !== fn)) };
  }, []);

  const getDashboard = useCallback(async () => {
    if (!dashboardRef || dashboardRef === 'default') {
      try {
        const dashboardResponse = await dashboards.getDefaultDashboard({ entityRef });
        handleDashboardChange(dashboardResponse);
      } catch (error) {
        if (error?.status === 404) {
          setIsLoading(false);
          setShowEmptyDashboard(true);
          setHeaderText(<Typography.H1>Dashboards</Typography.H1>);
        }
      }
    } else {
      try {
        const dashboardResponse = await dashboards.getDashboard({ entityRef, dashboardRef });
        handleDashboardChange(dashboardResponse);
      } catch (error) {
        if (error?.status === 404) {
          setIsLoading(false);
          setShowEmptyDashboard(true);
          setHeaderText(<Typography.H1>Dashboards</Typography.H1>);
        }
      }
    }
  }, [dashboardRef, entityRef, handleDashboardChange]);
  const listDashboards = useCallback(async () => {
    updateDashboardOptions();
  }, [entityRef]);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);
  useEffect(() => {
    listDashboards();
  }, [listDashboards]);
  useEffect(() => {
    updateSelectedDashboard();
  }, [updateSelectedDashboard]);
  useEffect(() => {
    const widgetElements = dashboard?.widgets?.map((widget, index) => {
      return (
        <Widget key={`${widget?.widgetId}-${index}`} widget={widget} refreshSubscribe={handleRefreshSubscribe} />
      );
    });

    setWidgets(widgetElements);
  }, [dashboard]);

  return (
    <DashboardStyled className="dashboard">
      <header>
        <nav>
          <ul>
            <li>
              <ActionButton
                icon="newOrderOutlinedIcon"
                label={<Localization.Translate stringId="newOrder" />}
                variant="outlined"
                onClick={handleNewOrderClick}
              />
            </li>
            <li>
              <CreateMenu onAddDashboard={handleAddDashboardClick} onAddWidget={handleAddWidgetClick} />
            </li>
            <li>
              <DateSelector handleShowCalendar={setShowCalendar} />
            </li>
            <li>
              <DashboardSelector
                onChange={handleDashboardSelectorChange}
                options={dashboardOptions}
                actions={dashboardActions}
                value={selectedDashboardOption}
              />
            </li>
            { !isLoading && dashboard && !showEmptyDashboard && <li>
                <FavoriteButton isFavorite={isFavorite} isLoading={isFavoriting} onClick={handleFavoriteDashboard} />
              </li>
            }
            { !isLoading && dashboard && !showEmptyDashboard && <li>
                <RefreshButton isRefreshing={isRefreshing} onClick={handleRefreshDashboard} />
              </li>
            }
          </ul>
        </nav>
      </header>
      {!isLoading && !showEmptyDashboard && !showCalendar && (
        <div className="mainContent">
          <div className="Container">{widgets}</div>
        </div>
      )}
      {isLoading && <Typography.H3>Loading...</Typography.H3>}
      {!isLoading && showEmptyDashboard && <EmptyDashboard onAddDashboard={handleAddDashboardClick}></EmptyDashboard>}
      {showCalendar && (
        <ContainerStyled>
          <DateRangePicker />
        </ContainerStyled>
      )}
      <AddDashboardDialog
        open={openAddDashboardDialog}
        onClose={handleCloseAddDashboardDialog}
        editDashboard={editDashboard}
      />
      <DuplicateDashboardDialog
        open={openDuplicateDashboardDialog}
        onClose={handleCloseDuplicateDashboardDialog}
        duplicateDashboard={duplicateDashboard}
      />
      <AddWidgetDialog open={openAddWidgetDialog} onClose={handleCloseAddWidgetDialog} editDashboard={editDashboard} />
      <DeleteDashboardDialog
        dashboard={deleteDashboard}
        open={!!deleteDashboard}
        onClose={closeDeleteDashboardDialog}
        onConfirm={handleDeleteDashboard}
      />
    </DashboardStyled>
  );
};

export default Dashboard;

import { http } from 'connex-cds';

export default {
  createWidget: ({ entityRef, widget }) => {
    return http.post({ apiName: 'dashboards-exp', path: `/${entityRef}/widgets`, data: widget });
  },
  getWidget: ({ entityRef, widgetRef }) => {
    return http.get({ apiName: 'dashboards-exp', path: `/${entityRef}/widgets/${widgetRef}` });
  },
  listWidgets: ({ entityRef }) => {
    return http.get({ apiName: 'dashboards-exp', path: `/${entityRef}/widgets` });
  },
  updateWidget: ({ entityRef, widget }) => {
    return http.patch({
      apiName: 'dashboards-exp',
      path: `/${entityRef}/widgets/${widget.crn}`,
      data: widget,
    });
  },
  deleteWidget: ({ entityRef, widgetRef }) => {
    return http.delete({ apiName: 'dashboards-exp', path: `/${entityRef}/widgets/${widgetRef}` });
  },
};

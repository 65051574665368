import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,

  dashboard: 'Dashboard',

  dashboardSelector_default: 'Dashboard predeterminado',
  dashboardSelector_action_share: 'Compartir Dashboard',
  dashboardSelector_action_edit: 'Editar Dashboard',
  dashboardSelector_action_duplicate: 'Duplicado Dashboard',
  dashboardSelector_action_delete: 'Eliminar Dashboard',

  dashboards_editDashboard_title: 'Editar Dashboard',
  dashboards_addDashboard_title: 'Crear Dashboard',
  dashboards_duplicateDashboard_title: 'Duplicado Dashboard',
  dashboards_addWidget_title: 'Agregar Widget',

  createMenu: 'Crear',
  createMenu_widget: 'Nuevo Widget',
  createMenu_dashboard: 'Nuevo Dashboard',
  createMenu_customer: 'Nuevo Customer',
  createMenu_project: 'Nuevo Project',
  createMenu_contact: 'Nuevo Contact',

  datePicker_today: 'Hoy',
  datePicker_yesterday: 'Ayer',
  datePicker_last_7_days: 'Último 7 días',
  datePicker_this_month: 'Este mes',
  datePicker_custom: 'Personalizado',
  datePicker_start: 'Seleccionar rango inicial',
  datePicker_end: 'Seleccionar rango final',
  addWidget: 'Agregar Widgets',
  createDashboard: 'Crear Dashboard',
  addWidgetInstructions: 'Crear o seleccionar otro Dashboard',
  addWidgetEmptyStateSpotlight: 'Nada aquí todavía',

  addDashboardDialog_widgetTab_title: 'Agregar Widget(s)',
  addDashboardDialog_widgetTab_search: 'Buscar Widgets',
  addDashboardDialog_widgetTab_widget_count: 'Recuento de widgets',

  addWidgetDialog_dialogAction_save: 'Guardar',
  addWidgetDialog_dialogAction_next: 'Siguiente',
  addWidgetDialog_dialogAction_back: 'Atrás',
  addWidgetDialog_widgetTab_title: 'Agregar Widget(s):',
  addWidgetDialog_widgetTab_search: 'Buscar Widgets',
  addWidgetDialog_widgetTab_widget_count: 'Recuento de widgets',

  notification_dashboardSaved: 'Dashboard cambios guardados',
  notification_newWidgetsAdded: 'Nuevos widgets agregados',

  widgetComparisonFromYesterday: 'De ayer',
  selectCompany: 'Seleccionar Empresa',
  newOrder: 'Nuevo orden',

  addDashboardDialog_tabs_dashboardInfo: 'Información',
  general_widget: 'Widgets',
  addDashboardDialog_dialogAction_save: 'Guardar',
  addDashboardDialog_dialogAction_next: 'Siguiente',
  addDashboardDialog_dialogAction_back: 'Atrás',
  addDashboardDialog_dashboardInfoTab_title: 'Agregar dashboard',

  addDashboardDialog_dashboardInfoTab_description: 'Agregar dashboard',
  addDashboardDialog_dashboardInfoTab_name: 'Nombre',
  addDashboardDialog_dashboardInfoTab_defaultWidgets: '¿Agregar widgets predeterminados?',
  addDashboardDialog_defaultWidgets_tooltip:
    'Se agregará un conjunto predeterminado de widgets cuando se cree este dashboard.',

  dashboardNotification_dashboardAdded: 'Nuevo dashboard agregado',
  dashboardNotification_dashboardDuplicated: 'Dashboard duplicado',
  tickets: 'Tickets',

  //==== GRID ====
  gridHeader_location_id: 'ID Ubicación',
  gridHeader_material_id: 'ID Material',
  gridHeader_material_name: 'Nombre Material',
  gridHeader_quantity: 'Cantidad',
  gridHeader_uom: 'UOM',

  gridHeaderMenu_menu_label: 'Menu',
  gridHeaderMenu_unsort_label: 'Desordenar',
  gridHeaderMenu_asc_label: 'Ordenar por ASC',
  gridHeaderMenu_desc_label: 'Ordenar por DESC',
  gridHeaderMenu_sort_label: 'Ordenar',
  gridHeaderMenu_hide_column_label: 'Ocultar columna',
  gridHeaderMenu_manage_columns_label: 'Gestionar columnas',
  gridHeaderMenu_find_column_label: 'Buscar column',
  gridHeaderMenu_search_column_label: 'Título de la columna',
  gridHeaderMenu_show_all_label: 'Mostrat todo',
  gridHeaderMenu_hide_all_label: 'Ocultar todo',
  gridHeaderMenu_show_columns_label: 'Columnas',

  gridHeader_plant: 'Planta',
  gridHeader_method: 'Método',
  gridHeader_total: 'Total',

  gridHeader_order: 'Orden',
  gridHeader_customer: 'Cliente',
  gridHeader_project: 'Proyecto',
  gridHeader_po: 'OC',

  gridFooter_rowCount_prefix: 'Mostrando',
  gridFooter_rowCount_suffix: 'registros',

  ticketStatus_tickets: 'Tickets',

  ticketStatus_received: 'Recibidos',
  ticketStatus_pending: 'Pendientes',
  ticketStatus_rejected: 'Rechazados',
  ticketStatus_accepted: 'Aceptado',

  gridHeader_loads: 'Cargas',
  gridHeader_avgLoad: 'Carga Promedio',
  gridHeader_avgBatchMinutes: 'Minutos Promedio por Lote',
  gridHeader_avgInYardMinutes: 'Tiempo Promedio de Carga',
  gridHeader_qtyPerHour: 'Cantidad por hora',

  gridHeader_product: 'Product',
  gridHeader_description: 'Descripción',
  gridHeader_avgBatchTime: 'Tiempo Promedio de Loteo',

  gridHeader_location: 'Ubicación',
  gridHeader_material: 'Material',
  gridHeader_target: 'Objetivo',
  gridHeader_actual: 'Real',
  gridHeader_totalVariance: 'Variación Total',
  gridHeader_avgVariance: 'Variación promedio',

  gridHeader_orderDate: 'Fecha de la Orden',
  gridHeader_orderNumber: 'Numero de Orden',
  gridHeader_orderStatus: 'Estado de la Orden',
  gridHeader_productName: 'Nombre Producto',
  gridHeader_unitPrice: 'Precio Unitario',

  gridHeader_orders: 'Pedidos',
  gridHeader_orderQuantity: 'Cantidad de Pedidos',
  gridHeader_shippedQuantity: 'Pedidos Enviados',
  gridHeader_cancelledQuantity: 'Pedidos Cancelados',
  gridHeader_excessiveUnloading: 'Exceso de Descarga',

  errors,
  themes,
};

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TextField } from '@mui/material';

export const SearchBarStyled = styled(TextField)`
  ${({ theme }) => css`
    width: fit-content;
    font-size: 16px;

    .MuiOutlinedInput-root.MuiInputBase-root {
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
      width: 290px;
      height: 48px;
      padding: 12px 15px;
      border-radius: 10px;

      &.Mui-disabled {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};

        .MuiOutlinedInput-notchedOutline {
          border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[50] })};
        }

        .MuiSvgIcon-root {
          color: ${theme.validateMode({ light: theme.colorsCA.neutral[80], dark: theme.colorsCA.neutral[30] })};
        }
      }

      .MuiSvgIcon-root {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[30], dark: theme.colorsCA.neutral[60] })};
      }
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.colorsCA.blue[50]};
        border-width: 2px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 10px;
      border-style: solid;
      border-color: ${theme.validateMode({ light: theme.colorsCA.neutral[50], dark: theme.colorsCA.neutral[90] })};
      border-width: 1px;
      box-shadow: ${theme.shadowCA(2)};
    }

    .MuiInputAdornment-root {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }
  `}
`;

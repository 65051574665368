import React, { useMemo } from 'react';
import { Localization } from 'connex-cds';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from '@mui/material';

import { supportedLocales } from '../../../../i18n/common';

import { ActionBarContainerStyled } from './styles';

const DateRangePicker = () => {
  const { userLocale } = Localization.useLocalizationContext();
  const translateMessage = Localization.useTranslateMessage();

  const localeText = useMemo(() => {
    return { start: translateMessage('datePicker_start'), end: translateMessage('datePicker_end') };
  }, [translateMessage]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={supportedLocales[userLocale]}>
      <MuiDateRangePicker
        format={supportedLocales?.[userLocale]?.formats?.L || 'MM/DD/YYYY'}
        localeText={localeText}
        closeOnSelect={false}
        slots={{
          actionBar: ({ onAccept, className }) => (
            <ActionBarContainerStyled className={className}>
              <Button onClick={onAccept}>Done</Button>
            </ActionBarContainerStyled>
          ),
        }}
        slotProps={{
          textField: {
            InputProps: {
              startAdornment: <CalendarMonthIcon fontSize="inherit" style={{ marginRight: 10 }} />,
            },
          },
          actionBar: {
            actions: ['accept'],
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;

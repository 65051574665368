import { DateTime } from 'luxon';
import { clearCachedItem, getCachedItem } from '../../util/cache';

const LATER_DELAY = { unit: 'hours', value: 24 };
const RELOAD_DELAY = { unit: 'hours', value: 2 };
export default (clientVersion, version) => {
  const versionsMatch = clientVersion === version;

  if (versionsMatch) {
    clearCachedItem('version');
    return false;
  }

  const notification = getCachedItem('version');

  if (notification) {
    if (notification.version !== version) {
      return true;
    }

    const diff = DateTime.now().diff(DateTime.fromISO(notification.timestamp), LATER_DELAY.unit)?.[LATER_DELAY.unit];
    if (notification.action === 'later') {
      if (diff < LATER_DELAY.value) {
        return false;
      }
    }
    if (notification.action === 'reload') {
      if (diff < RELOAD_DELAY.value) {
        return false;
      }
    }
  }

  return true;
};

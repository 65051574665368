import React from 'react';
import { API } from '../../components/api';
import { usePostMessageContext } from '../../post-message/PostMessage';
import { EntityContext } from './context';
import { usePostMessageListener } from './usePostMessageListener';

export const EntityContextProvider = ({ children }) => {
  const { sendMessage } = usePostMessageContext();
  const [entityRef, setEntityRef] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const _setEntityRef = React.useCallback(
    ref => {
      // Don't call setEntityRef here.
      // It will get called after CONNEX's response to this message is received (below).
      sendMessage({ type: 'entity-ref', entityRef: ref });
      setIsLoading(true);
    },
    [sendMessage]
  );

  const entityRefListener = React.useCallback(
    message => {
      if (message.type === 'entity-context') {
        setEntityRef(message.entityRef);
        API.setAppEntityToken(message?.token);
        setIsLoading(false);
      }
    },
    [setEntityRef]
  );

  usePostMessageListener(entityRefListener);

  React.useEffect(() => {
    sendMessage({ type: 'req-entity-context' });
  }, [sendMessage]);

  return (
    <EntityContext.Provider value={{ entityRef, setEntityRef: _setEntityRef, isLoading }}>
      {children}
    </EntityContext.Provider>
  );
};

import { css } from '@emotion/react';

export const DataGridTableStyles = ({ theme, loading }) => css`
  flex: initial;
  border: 1px solid ${theme.colorsCA.neutral[50]};
  box-shadow: ${theme.validateMode({ light: '0px 4px 20px rgba(101, 119, 136, 0.2)', dark: theme.shadowCA(4) })};
  overflow: hidden;
  height: ${loading ? '100%' : 'auto'};
  max-height: 100%;

  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .row-count {
    display: flex;
    justify-content: flex-end;
    height: 38px;
    align-items: center;
    flex-shrink: 0;
    color: ${theme.validateMode({
      light: theme.colorsCA.neutral[40],
      dark: theme.colorsCA.neutral[95],
    })};
    background: ${theme.validateMode({
      light: theme.colorsCA.neutral[101],
      dark: theme.colorsCA.neutral[40],
    })};
    padding: 9px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    border-top: 1px solid ${theme.validateMode({
      light: theme.colorsCA.neutral[80],
      dark: theme.colorsCA.neutral[80],
    })};
    border-top: 1px solid var(--neutral-50, #787579);
    border-radius: 0px 0px 10px 10px;
  }

  > .MuiDataGrid-main {
    box-shadow: 0px -1px 3px 1px rgba(0, 0, 0, 0.15), 0px -1px 2px 0px rgba(0, 0, 0, 0.30);

    > .MuiDataGrid-columnHeaders {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      z-index: 3;
      overflow: hidden;
      background: ${theme.validateMode({
        light: theme.colorsCA.neutral[100],
        dark: 'transparent',
      })};
      .MuiDataGrid-columnHeader {
        padding: 0 12px;
        overflow: hidden;
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: -4px;
          width: 4px;
          height: 100%;
          background: none;
          filter: none;
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }
        &:not(.MuiDataGrid-columnHeader--alignCenter) {
          .MuiDataGrid-columnHeaderTitleContainer {
            justify-content: space-between;
          }
        }

        &:hover {
          .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-menuIcon {
            margin: 0;
          }
        }

        .MuiDataGrid-columnHeaderTitleContainer {
          .MuiDataGrid-iconButtonContainer {
            color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
          }

          .MuiDataGrid-columnHeaderTitle {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
          }
        }

        .MuiDataGrid-menuIcon {
          margin: 0;
          .MuiDataGrid-menuIconButton {
            background: ${theme.validateMode({
              light: theme.colorsCA.neutral[95],
              dark: theme.colorsCA.neutral[20],
            })};
            color: ${theme.validateMode({ light: '#79747E', dark: theme.colorsCA.neutral[90] })};
          }
        }
      }
    }

    > .MuiDataGrid-virtualScroller {
      overflow: ${loading ? 'hidden' : 'auto'};
      background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
      // firefox custom scroll styles
      scrollbar-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })}
        ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 6px;
        height: 7px;
      }

      &::-webkit-scrollbar-track {
        background-color: ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
        border-radius: 8px;
        border: none;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })};
        border-radius: 8px;
      }

      .MuiDataGrid-overlayWrapper {
        .skeleton-cell {
          height: 54px;
        }
      }

      .MuiDataGrid-row.main-grid-row {
        border-bottom: 1px solid
          ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }

        &.MuiDataGrid-row--detailPanelExpanded,
        &:last-of-type {
          border-bottom: none;
        }
        &.Mui-selected {
          background: ${theme.validateMode({
            light: theme.colorsCA.blue[95],
            dark: theme.colorsCA.darkBlue[60],
          })};
          &.voided {
            background: ${theme.validateMode({ light: '#FFF6F7', dark: '#986977' })};
          }
        }
        &::hover,
        &.Mui-hovered {
          background: ${theme.validateMode({
            light: theme.surfaceCA(1),
            dark: 'linear-gradient(0deg, rgba(208, 188, 255, 0.14) 0%, rgba(208, 188, 255, 0.14) 100%), #434247',
          })};
          &.voided {
            background: ${theme.validateMode({ light: '#FFF6F7', dark: '#633B48' })};
          }
        }

        &.voided {
          background: ${theme.validateMode({ light: '#FFF6F7', dark: '#492532' })};
        }

        .MuiDataGrid-cell {
          border: none;

          .detail-toggler-button {
            width: 24px;
            height: 24px;
            padding: 0;
            color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
          }

          .icon {
            &.state {
              color: ${theme.colorsCA.red[60]};
              font-size: 25px;
            }

            &.billable {
              color: ${theme.colorsCA.green[60]};
            }

            &.notes {
              color: ${theme.colorsCA.blue[50]};
            }
          }

          .MuiDataGrid-cellContent {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            white-space: nowrap;
          }
        }
      }

      .MuiDataGrid-detailPanel {
        overflow: initial;
        border-bottom: 1px solid
          ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
        // firefox custom scroll styles
        scrollbar-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })}
          ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 6px;
          height: 7px;
        }

        &::-webkit-scrollbar-track {
          background-color: ${theme.validateMode({ light: '#e7e7e7', dark: theme.colorsCA.darkBlue[70] })};
          border-radius: 8px;
          border: none;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${theme.validateMode({ light: '#a6a7a9', dark: theme.colorsCA.darkBlue[40] })};
          border-radius: 8px;
        }
      }
    }
  }

  > .MuiDataGrid-footerContainer {
    display: flex;
    background: ${theme.backgroundCA('tableHF')};
    box-shadow: ${theme.validateMode({
      light: '0px -1px 3px 1px rgba(0, 0, 0, 0.15), 0px -1px 2px rgba(0, 0, 0, 0.3)',
      dark: '0px -1px 2px 0px rgba(0, 0, 0, 0.30), 0px -1px 3px 1px rgba(0, 0, 0, 0.15)',
    })};
    border-radius: 0 0 10px 10px;
    border-top: 1px solid ${theme.colorsCA.neutral[50]};
    padding: 0 15px;
    .selection-wrapper,
    .records-wrapper {
      flex-grow: inherit;
      flex-direction: row;
      align-items: center;
      gap: 21px;
    }
    .columns-button {
      padding: 3px 5px;
      font-size: 13px;
      line-height: inherit;
      color: ${theme.colorsCA.blue[50]};
      text-transform: uppercase;
    }
    .refresh-button {
      width: 32px;
      height: 32px;
      background: ${theme.colorsCA.blue[50]};
      color: ${theme.colorsCA.neutral[100]};
    }
    p {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[0] })};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
`;

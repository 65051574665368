import { css } from '@emotion/react';

export default ({ theme }) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  height: 100%;
  width: 100%;

  header {
    width: 100%;
    padding: 0 0 0 16px;
    .widget-icon {
      color: ${theme.validateMode({ light: theme.colorsCA.green[60], dark: theme.colorsCA.green[60] })};
      margin-right: 16px;

      &.positive {
        color: ${theme.validateMode({ light: theme.colorsCA.green[60], dark: theme.colorsCA.green[60] })};
      }
      &.negative {
        color: ${theme.validateMode({ light: theme.colorsCA.yellow[30], dark: theme.colorsCA.yellow[80] })};
      }
      &.neutral {
        color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
      }
      &.danger {
        color: ${theme.validateMode({ light: theme.colorsCA.red[40], dark: theme.colorsCA.red[50] })};
      }
    }
    .kebab-icon {
      margin-left: auto;
    }
  }

  .title {
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
    font-size: 22px;
    font-weight: 550;
    line-height: 0;
    margin-top: 1em;
    margin-left: 1em;
  }

  .widget-data {
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 1 1 auto;
    justify-content: center;
  }

  .ticket-count {
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
    font-size: 28px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 71.429% */
    text-transform: capitalize;
  }

  .selected-status {
    text-transform: capitalize;
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[70], dark: theme.colorsCA.neutral[75] })};
    font-size: 24px;
    font-style: normal;
    font-weight: 550;
    line-height: 20px; /* 83.333% */
    letter-spacing: 0.25px;
    margin-top: 19px;
  }

  footer {
    width: 100%;
    padding: 0 0 20px 16px;
    color: ${theme.validateMode({ light: theme.colorsCA.green[60], dark: theme.colorsCA.green[60] })};
    font-size: 18px;
    font-weight: 500;

    .ticket-count-comparison {
      border-radius: 20px;
      padding: 10px;
      margin-right: 15px;
      background-color: ${theme.validateMode({ light: theme.colorsCA.green[95], dark: theme.colorsCA.green[95] })};
    }

    &.positive {
      color: ${theme.validateMode({ light: theme.colorsCA.green[60], dark: theme.colorsCA.green[60] })};
      .ticket-count-comparison {
        background-color: ${theme.validateMode({ light: theme.colorsCA.green[95], dark: theme.colorsCA.green[8030] })};
      }
    }
    &.negative {
      color: ${theme.validateMode({ light: theme.colorsCA.yellow[30], dark: theme.colorsCA.yellow[80] })};
      .ticket-count-comparison {
        background-color: ${theme.validateMode({
          light: theme.colorsCA.yellow[95],
          dark: theme.colorsCA.yellow[3030],
        })};
      }
    }
    &.neutral {
      color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
      .ticket-count-comparison {
        background-color: ${theme.validateMode({
          light: theme.colorsCA.neutral[95],
          dark: theme.colorsCA.neutral[40],
        })};
      }
    }
    &.danger {
      color: ${theme.validateMode({ light: theme.colorsCA.red[40], dark: theme.colorsCA.red[50] })};
      .ticket-count-comparison {
        background-color: ${theme.validateMode({ light: theme.colorsCA.red[95], dark: theme.colorsCA.red[95] })};
      }
    }
  }
`;

import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiCloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled from '@emotion/styled';

import styles from './styles';

const Styled = styled(MuiDialogTitle)`
  ${styles}
`;

const DialogTitle = ({ children, onClose = null, CloseIcon = null }) => (
  <Styled className="dialog-title">
    {children}
    {onClose && (
      <IconButton className="close-icon-button" variant="outlined" onClick={onClose}>
        {!Boolean(CloseIcon) && <MuiCloseIcon className="close-icon" />}
        {Boolean(CloseIcon) && CloseIcon}
      </IconButton>
    )}
  </Styled>
);

export default DialogTitle;

import { useMemo } from 'react';
import { Localization } from 'connex-cds';

export const TABLE_CLASSNAMES = {
  row: 'main-grid-row',
};

const UNHIDEABLE_COLUMNS = ['__check__', '__detail_panel_toggle__'];

export const getTogglableColumns = columns =>
  columns.filter(column => !UNHIDEABLE_COLUMNS.includes(column.field)).map(column => column.field);

export const useTableLocaleText = () => {
  const translateMessage = Localization.useTranslateMessage();

  const localeText = useMemo(
    () => ({
      columnMenuLabel: translateMessage('gridHeaderMenu_menu_label'),
      columnMenuUnsort: translateMessage('gridHeaderMenu_unsort_label'),
      columnMenuSortAsc: translateMessage('gridHeaderMenu_asc_label'),
      columnMenuSortDesc: translateMessage('gridHeaderMenu_desc_label'),
      columnHeaderSortIconLabel: translateMessage('gridHeaderMenu_sort_label'),
      columnMenuHideColumn: translateMessage('gridHeaderMenu_hide_column_label'),
      columnMenuManageColumns: translateMessage('gridHeaderMenu_manage_columns_label'),
      columnsPanelTextFieldLabel: translateMessage('gridHeaderMenu_find_column_label'),
      columnsPanelTextFieldPlaceholder: translateMessage('gridHeaderMenu_search_column_label'),
      columnsPanelShowAllButton: translateMessage('gridHeaderMenu_show_all_label'),
      columnsPanelHideAllButton: translateMessage('gridHeaderMenu_hide_all_label'),
      toolbarColumns: translateMessage('gridHeaderMenu_show_columns_label'),
    }),
    [translateMessage]
  );

  return localeText;
};

import React, { useMemo } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Localization } from 'connex-cds';
import { TABLE_CLASSNAMES, useTableLocaleText } from './config';
import { TablePopper, TableColumnsPanel, TablePanel, TableSkeleton } from '../../../../core';
import PropTypes from 'prop-types';
import { DataGridTableStyles } from './style';
import styled from '@emotion/styled';

const Styled = styled(DataGridPro)`
  ${DataGridTableStyles}
`;

const Table = ({ data, columns, activeColumns }) => {
  const translateMessage = Localization.useTranslateMessage();
  const localeText = useTableLocaleText();
  const rows = useMemo(() => (data?.length ? data : []), [data]);

  return (
    <Styled
      scrollbarSize={6}
      className="main-grid"
      localeText={localeText}
      classes={TABLE_CLASSNAMES}
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableColumnReorder
      disableColumnFilter
      disableColumnPinning
      disableDensitySelector
      rows={rows}
      getRowId={row => row.crn}
      rowHeight={56}
      columnHeaderHeight={44}
      columns={columns}
      slots={{
        loadingOverlay: TableSkeleton,
        basePopper: TablePopper,
        columnsPanel: TableColumnsPanel,
        panel: TablePanel,
        footer: () => (
          <div className="row-count">
            {translateMessage('gridFooter_rowCount_prefix')} {rows.length} {translateMessage('gridFooter_rowCount_suffix')}
          </div>
        ),
      }}
      rowBuffer={3}
      rowThreshold={3}
      getDetailPanelHeight={() => 'auto'}
    />
  );
};

Table.propTypes = {
  data: PropTypes.array,
  activeColumns: PropTypes.object,
};

export default Table;

import React from 'react';
import * as MaterialIcons from '@mui/icons-material';

export const MaterialIcon = ({ iconName, showDefaultIcon = true, ...props }) => {
  const Icon = MaterialIcons[iconName];

  if (!Icon) {
    return showDefaultIcon ? <MaterialIcons.HelpOutlineOutlined {...props} /> : null;
  }

  return <Icon {...props} />;
};

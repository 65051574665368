import { css } from '@emotion/react';

export default () => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
  height: 100%;
  justify-content: center;

  .spotlight {
    font-size: 22px;
    margin-top: 1rem;
  }

  .text {
    font-size: 16px;
  }

  .buttons-down {
    font-size: 16px;
    margin-top: 1rem;
    gap: 11px;
  }
`;

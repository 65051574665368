import { css } from '@emotion/react';

export default ({ theme }) => css`
  position: relative;
  display: flex;
  font-size: 30px;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;

  .close-icon-button {
    position: absolute;
    top: 20px;
    right: 22px;
    padding: 5px;
    border: 1px solid ${theme.validateMode({ light: '#cac4d0', dark: '#49454F' })};
    box-shadow: ${theme.validateMode({
      light: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
      dark: '0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
    })};

    .close-icon {
      font-size: 24px;
    }
  }
`;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import widgets from '../queries/widgets';

export const useListWidgets = () => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['widgets', entityRef],
    queryFn: () => widgets.listWidgets({ entityRef }),
    staleTime: Infinity,
    enabled: !!entityRef,
  });
};

export const useGetWidget = widgetRef => {
  const { entityRef } = useParams();

  return useQuery({
    queryKey: ['widgets', entityRef],
    queryFn: () => widgets.getWidget({ entityRef, widgetRef }),
    staleTime: Infinity,
    enabled: !!entityRef || !widgetRef,
  });
};

export const useCreateWidget = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['widgets', entityRef];

  const mutation = useMutation({
    mutationFn: ({ widget }) => widgets.createWidget({ entityRef, widget }),
    onError: (_err, _response, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};

export const useUpdateWidget = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['widgets', entityRef];
  const mutation = useMutation({
    mutationFn: ({ widget }) => widgets.updateWidget({ entityRef, widget }),
    onError: (_err, _response, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};

import styled from '@emotion/styled';
import Tooltip from '@mui/material/Tooltip';

import styles from './styles';

const Styled = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)`
  ${styles}
`;

export default Styled;

import React from 'react';

import { MaterialIcon } from '../../../components/icons/MaterialIcon';

import { ProfileStyled } from './styles.js';

export const Profile = ({ onClick }) => {
  return (
    <ProfileStyled className="profile" onClick={onClick}>
      <MaterialIcon iconName="PermIdentityOutlined" sx={{ fontSize: 32 }} />
    </ProfileStyled>
  );
};

import { css } from '@emotion/react';

const TablePanelStyles = ({ theme }) => css`
  .MuiPaper-root {
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
    border-radius: 10px;
    box-shadow: ${theme.shadowCA(2)};
  }
`;

export default TablePanelStyles;

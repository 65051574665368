import styled from '@emotion/styled';
import classNames from 'classnames';
import styles from './styles';
import { TicketCountByStatus } from './core-widgets/ticket-count-by-status';
import { RawMaterialRequirements } from './core-widgets/raw-material-requirements';
import { CodRecapByPaymentMethod } from './core-widgets/cod-recap-by-payment-method';
import { CodRecapByOrder } from './core-widgets/cod-recap-by-order';
import { TicketCountByStatusBarChart } from './core-widgets/ticket-count-by-status-bar-chart';
import { LocationProductionStatistics } from './core-widgets/location-production-statistics';
import { LocationProductionByProduct } from './core-widgets/location-production-by-product';
import { BatchVarianceByMaterialAndLocation } from './core-widgets/batch-variance-by-material-and-location';
import { PricedOrdersForDateRange } from './core-widgets/priced-orders-for-date-range';
import { CustomerScorecard } from './core-widgets/customer-scorecard';

const Styled = styled.div`
  ${styles}
`;

const widgetComponents = {
  'ticket-count-by-status': TicketCountByStatus,
  'ticket-count-by-status-bar-chart': TicketCountByStatusBarChart,
  'raw-material-requirements': RawMaterialRequirements,
  'cod-recap-by-payment-method': CodRecapByPaymentMethod,
  'cod-recap-by-order': CodRecapByOrder,
  'location-production-statistics': LocationProductionStatistics,
  'location-production-by-product': LocationProductionByProduct,
  'batch-variance-by-material-and-location': BatchVarianceByMaterialAndLocation,
  'priced-orders-for-date-range': PricedOrdersForDateRange,
  'customer-scorecard': CustomerScorecard,
};

const Widget = ({ widget, refreshSubscribe }) => {
  if (!widget && !widget.widgetId) return null;

  const WidgetComponent = widgetComponents[widget.widgetId];

  if (!WidgetComponent) return null;

  return (
    <Styled className={classNames('widget', `${widget?.options?.selectedSize ? widget.options.selectedSize : ''}`)}>
      <WidgetComponent widget={widget} refreshSubscribe={refreshSubscribe} />
    </Styled>
  );
};

export default Widget;

import React from 'react';
import { ButtonStyled } from './styles';
import PropTypes from 'prop-types';
import Icon from '../custom-icon';

const ActionButton = props => {
  const { icon, label } = props;

  return (
    <ButtonStyled {...props}>
      {icon && <Icon iconName={icon} />}
      {label}
    </ButtonStyled>
  );
};

ActionButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.node.isRequired,
};

export default ActionButton;

const yellow = {
  10: '#E77F13',
  20: '#EBA625',
  30: '#EDBE2F',
  40: '#EFD539',
  50: '#EDE53D',
  60: '#F1EA5A',
  70: '#F4EE78',
  80: '#F6F39E',
  90: '#FAF8C4',
  95: '#FDFDE7',
  100: '#FFFFFF',
  3030: '#F4EE784D',
};

export default yellow;

import { css } from 'styled-components';

export default css`
  position: relative;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    padding: 20px 0px 40px 0px;
    color: var(--color-text-page); //#605d62; //

    .left {
      .feature-name {
        font-weight: 600;
        font-size: 40px;
        font-style: normal;
        line-height: normal;
      }
      .entity-switcher {
      }
      &.visible {
        .feature-name {
        }
        .entity-switcher {
        }
      }
    }

    & > .right {
      flex: 1;
      text-align: right;
      .center-aligned {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
      }
    }
  }
`;

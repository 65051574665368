import * as yup from 'yup';

const dashboardInfoValidationSchema = yup.object().shape({
  selectedWidgets: yup.object().test(
    "selected-widgets",
    "At least one widget is required",
    (newValue) => {
      if (!newValue) { return false };
      const widgets = Object.values(newValue).flat(Infinity).filter(Boolean);
      return widgets?.length > 0;
    }).required("You must add at least one widget")
});

export const stepValidationSchemas = [dashboardInfoValidationSchema];

export const validationSchema = stepValidationSchemas.reduce(
  (composedSchema, stepSchema) => composedSchema.concat(stepSchema),
  yup.object().shape({})
);

export const defaultValues = { widgets: [] };

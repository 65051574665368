import { css } from '@emotion/react';

export default ({ theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;

  .widget-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 32px;
    text-align: center;
  }

  .search-filter {
    margin-bottom: 32px;
  }

  .widgets-form {
    display: inline-flex;
    width: 100%;
    margin: 0px;
  
    justify-content: center;
    align-items: flex-start;

    &.single-widget {
      justify-content: flex-start;
    }
  }

  .Container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
  }
`;

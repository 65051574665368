import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from '@emotion/styled';
import IconStyles from './styles';
import iconNames from './iconList';

const Icon = ({ iconName, classNameProp, ...props }) => {
  const IconComponent = iconNames[iconName];
  if (!IconComponent) return null;
  return <IconComponent className={cn('icon', classNameProp)} {...props} />;
};

const Styled = styled(Icon)`
  ${IconStyles}
`;

Icon.propTypes = {
  iconName: PropTypes.string,
  classNameProp: PropTypes.string,
  onClick: PropTypes.func,
};

export default Styled;

const neutral = {
  0: '#000000',
  10: '#1C1B1F',
  20: '#313033',
  30: '#484649',
  40: '#605D62',
  50: '#787579',
  60: '#939094',
  70: '#AEAAAE',
  80: '#C9C5CA',
  90: '#E6E1E5',
  95: '#F4EFF4',
  99: '#FFFBFE',
  100: '#FFFFFF',
  101: '#F8FAFD',
  102: '#484649',
};

export default neutral;

import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';

import { MenuItemStyle } from './style';

const Styled = styled(MenuItem)`
  ${MenuItemStyle}
`;

const SelectableMenuAction = ({ action, onClick, renderAction }) => {
  const { onClick: onActionClick, icon, label } = action;

  const handleClick = () => {
    if (onActionClick) {
      onActionClick();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <Styled className="selected-menu__action" onClick={handleClick}>
      {!!icon && icon}
      {renderAction ? renderAction(action) : label}
    </Styled>
  );
};

SelectableMenuAction.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
  }),
  onClick: PropTypes.func.isRequired,
  renderAction: PropTypes.func,
};

export default SelectableMenuAction;

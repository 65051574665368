import { forwardRef } from 'react';
import { Localization } from 'connex-cds';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { func, bool, string } from 'prop-types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import InputTextBase from '../input-text-base/InputTextBase';
import { FormTooltip } from '..';

const TooltipIcon = forwardRef((props, ref) => <InfoOutlinedIcon {...props} ref={ref} className="info-icon" />);

const TextInput = ({ tooltipInfoStringId, withClearAdornment, getInputProps, ...props }) => {
  const translateMessage = Localization.useTranslateMessage();

  return (
    <InputTextBase
      {...props}
      getInputProps={({ name, setFieldValue }) => ({
        ...getInputProps({ name, setFieldValue }),
        endAdornment: (
          <>
            {getInputProps({ name, setFieldValue })?.endAdornment}
            {tooltipInfoStringId && (
              <FormTooltip
                arrow
                title={translateMessage(tooltipInfoStringId)}
                placement="top-start"
                PopperProps={{ disablePortal: true }}
              >
                <TooltipIcon />
              </FormTooltip>
            )}
            {withClearAdornment && (
              <IconButton
                aria-label="toggle"
                onClick={() => {
                  setFieldValue(name, '');
                }}
                onMouseDown={null}
                edge="end"
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </>
        ),
      })}
    />
  );
};

TextInput.propTypes = {
  withClearAdornment: bool,
  getInputProps: func,
  tooltipInfoStringId: string,
};

TextInput.defaultProps = {
  withClearAdornment: false,
  getInputProps: () => {},
  type: 'text',
};

export default TextInput;

import { css } from '@emotion/react';

export default ({ theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25rem;

  .dashboard-info-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 25px;
  }

  .dashboard-info-description {
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 40px;
  }

  .dashboard-info-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;

    .MuiTextField-root {
      flex: 1;
    }

    .MuiInputBase-input {
      font-size: 16px;
    }

    .MuiSelect-select {
      height: 40px;
      padding: 4px 16px;
      display: flex;
      align-items: center;
    }
  }
`;

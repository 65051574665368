import cn from 'classnames';
import { cloneDeep } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import pkg from '../package.json';
import { AppHeader } from './AppHeader';
import { AppListener } from './AppListener';
import { API } from './components/api';
import { AuthProvider } from './components/auth';
import { DrawerProvider } from './components/drawer';
import { Column } from './components/layout';
import { Localization } from './components/localization';
import { ModalProvider } from './components/modal';
import { ThemeProvider } from './components/theme';
import { UserProvider } from './components/user';
import fullStoryInit from './fullstory';
import NewSidebar from './layout/new-sidebar';
import { PostMessage } from './post-message/PostMessage';
import { ProductionWarningProvider } from './ProductionWarningProvider';
import { AppDataProvider } from './util/app-data/AppData';
import AppContextProvider from './util/app-provider/AppContext';
import { getCachedItem } from './util/cache';
import { EntityContextProvider } from './util/entity-provider/EntityProvider';
import MuiThemeProvider from './util/mui-theme-provider/MuiThemeProvider';
import { VersionHandler } from './version-handler/VersionHandler';

const Styled = styled(Column)`
  transition: all 0.3s;
  background-color: var(--color-background-page);
  color: var(--color-text-page);
  font-family: 'Work Sans', serif;
  height: 100vh;
  width: 100%;
  gap: 0;
  .ant-drawer-body {
    padding-top: 0;
  }
`;

export const ConnexDesignSystem = ({
  onLogin,
  toLogin,
  children,
  className,
  messages,
  initialLocale,
  initialTimeZone,
  app,
  isIframe = true,
  appId,
  analytics = true,
  contentOnly = false, // Hides sidebar and header.
  queryClient,
  setApiSource = true,
  ...props
}) => {
  React.useEffect(() => {
    if (analytics) fullStoryInit();
  }, [analytics, appId]);

  React.useEffect(() => {
    if (queryClient) {
      const ONE_HOUR = 1000 * 60 * 60;
      const TWELVE_HOURS = ONE_HOUR * 12;

      const options = cloneDeep(queryClient.getDefaultOptions());

      const defaultQueryOptions = {
        retry: (failureCount, error) => error?.status < 400 || error?.status >= 500,
        staleTime: 5000,
        cacheTime: TWELVE_HOURS,
      };

      const mergedDefaultOptions = {
        ...options,
        queries: {
          ...defaultQueryOptions,
          ...options.queries,
        },
      };
      queryClient.setDefaultOptions?.(mergedDefaultOptions);
    }
  }, [queryClient]);

  if (setApiSource) {
    API.setSource(appId);
  }

  return (
    <Styled className={cn('cds-root-container', `v-${pkg.version}`, className)} {...props}>
      <AppDataProvider appId={appId} {...props}>
        <PostMessage isIframe={isIframe} sendMessages={true}>
          <Localization
            messages={messages}
            initialLocale={initialLocale || getCachedItem('language')}
            isIframe={isIframe}
            appId={appId}
            initialTimeZone={initialTimeZone}
          >
            <ThemeProvider isIframe={isIframe}>
              <MuiThemeProvider>
                <VersionHandler>
                  <UserProvider>
                    <AuthProvider onLogin={onLogin} toLogin={toLogin}>
                      <ModalProvider>
                        <DrawerProvider>
                          <AppListener>
                            <EntityContextProvider>
                              <AppContextProvider>
                                <ProductionWarningProvider>
                                  {contentOnly ? (
                                    children
                                  ) : (
                                    <NewSidebar>
                                      <AppHeader />
                                      {children}
                                    </NewSidebar>
                                  )}
                                </ProductionWarningProvider>
                              </AppContextProvider>
                            </EntityContextProvider>
                          </AppListener>
                        </DrawerProvider>
                      </ModalProvider>
                    </AuthProvider>
                  </UserProvider>
                </VersionHandler>
              </MuiThemeProvider>
            </ThemeProvider>
          </Localization>
        </PostMessage>
      </AppDataProvider>
    </Styled>
  );
};

import React, { useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from '@mui/material/styles/ThemeProvider';

import { useThemeContext } from '../../components/theme';
import getDesignConfig from '../../mui-theme/modes/config';

const MuiThemeProvider = ({ children }) => {
  const { themeName } = useThemeContext();

  const theme = useMemo(() => createTheme(getDesignConfig(themeName)), [themeName]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiThemeProvider;

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CategoryOutlined from '@mui/icons-material/CategoryOutlined';
import Delete from '@mui/icons-material/Delete';
import EditOutlined from '@mui/icons-material/EditOutlined';
import DriveFileRenameOutlineRounded from '@mui/icons-material/DriveFileRenameOutlineRounded';
import InventoryOutlined from '@mui/icons-material/InventoryOutlined';
import { ReactComponent as AddIcon } from '../../assets/icons/order-icon.svg';

const iconList = {
  addOutlinedIcon: props => <AddOutlinedIcon {...props} />,
  personAddAltOutlinedIcon: props => <PersonAddAltOutlinedIcon {...props} />,
  personOutlineIcon: props => <PersonOutlineIcon {...props} />,
  categoryOutlined: props => <CategoryOutlined {...props} />,
  deleteIcon: props => <Delete {...props} />,
  editOutlinedIcon: props => <EditOutlined {...props} />,
  driveFileRenameOutlineRounded: props => <DriveFileRenameOutlineRounded {...props} />,
  inventoryOutlined: props => <InventoryOutlined {...props} />,
  newOrderOutlinedIcon: props => <AddIcon {...props} />,
};
export default iconList;

import { css } from '@emotion/react';

const ColumnMenuStyles = ({ theme }) => css`
  .MuiListItemIcon-root,
  .MuiListItemText-root {
    color: ${theme.palette.text.primary};
  }
`;

export default ColumnMenuStyles;

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';

export const ButtonStyled = styled(Button)`
  ${({ theme }) => css`
    display: flex;
    align-item: center;
    gap: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 24px;
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
    border: 1px solid ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[10] })};

    &:hover {
      background: ${theme.validateMode({
        light: 'linear-gradient(0deg, rgba(0, 43, 84, 0.05) 0%, rgba(0, 43, 84, 0.05) 100%)',
        dark: 'linear-gradient(0deg, rgba(85, 187, 233, 0.05) 0%, rgba(85, 187, 233, 0.05) 100%)',
      })};
    }
  `}
`;

import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { MenuItemStyle } from './style';

const Styled = styled(MenuItem)`
  ${MenuItemStyle}
`;

const SelectableMenuItem = ({ option, isSelected, renderItemValue, onClick, withCheckMark }) => {
  return (
    <Styled className={cn('selectable-menu__item', { SelectableMenuItem__selected: isSelected })} onClick={onClick}>
      {withCheckMark && isSelected && <CheckIcon />}
      {renderItemValue ? renderItemValue(option) : option.label}
    </Styled>
  );
};

SelectableMenuItem.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  renderItemValue: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  withCheckMark: PropTypes.bool,
};

export default SelectableMenuItem;

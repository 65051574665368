import { css } from '@emotion/react';

export default ({ theme }) => css`
  background: red;
  border: 1px solid gray;

  width: 424px;
  height: 265px;
  padding: 0px;

  &.medium {
    width: calc((424px * 2) + 20px);
    height: calc((265px * 2) + 20px);
  }

  filter: drop-shadow(0px 6px 3px rgba(101, 119, 136, 0.2));

  background: ${theme.validateMode({ light: 'rgb(255, 255, 255)', dark: '#35313F' })};
  border: 1px solid ${theme.validateMode({ light: '#CAC4D0', dark: '#938F99' })};
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
`;

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ProfileStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid ${theme.colorsCA.darkBlue[60]};
    cursor: pointer;

    ${theme.validateMode({
      light: `background: ${theme.colorsCA.neutral[100]}; box-shadow: ${theme.shadowCA(6)};`,
      dark: theme.elevationCA[6],
    })};

    .MuiSvgIcon-root {
      color: ${theme.colorsCA.darkBlue[60]};
    }
  `}
`;

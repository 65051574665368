import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Localization } from 'connex-cds';
import widgetQueries from '../../../../../../api/queries/widget-queries';
import { BarChart } from '@mui/x-charts/BarChart';
import styles from './styles';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const Styled = styled.div`
  ${styles}
`;
const DEFAULT_REFRESH_INTERVAL = 120000;

const TicketCountByStatusBarChart = ({ widget, refreshSubscribe, onKebabAction }) => {
  const { entityRef } = useParams();
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pending, setPending] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [refreshInterval, setRefreshInterval] = useState(widget?.refreshInterval || DEFAULT_REFRESH_INTERVAL);
  const translateMessage = Localization.useTranslateMessage();

  const getItems = useCallback(async ({ dateOption = 'Today', startDate, endDate }) => {
    const queryName = widget.queryIdentifier;
    const payload = {
      dateOption,
      startDate,
      endDate,
    };
    try {
      const result = await widgetQueries.createQuery({ entityRef, queryName, payload });
      return result?.items;
    } catch (error) {
      console.log(error);
    }
  });

  const getCurrentItems = useCallback(async () => {
    const items = await getItems({ dateOption: 'Today' });
    if (items && items.length) {
      const getValue = key => (items[0].data?.[key] !== undefined ? items[0].data[key] : 0);

      setPending(getValue('PENDING'));
      setAccepted(getValue('ACCEPTED'));
      setRejected(getValue('REJECTED'));
    }
  }, [entityRef, widget]);

  const getWidgetData = useCallback(async () => {
    setLoading(true);
    await getCurrentItems();
    setLoading(false);
    setInitialLoad(false);
  }, [getCurrentItems]);

  const minY = useMemo(() => {
    const maxValue = Math.max(pending, accepted, rejected);
    const minValue = Math.min(pending, accepted, rejected);
    return minValue / maxValue < 0.02 && maxValue > 100 ? -1 : null;
  }, [pending, accepted, rejected]);

  const xAxis = useMemo(() => {
    return [
      {
        scaleType: 'band',
        data: [
          translateMessage('ticketStatus_pending'),
          translateMessage('ticketStatus_accepted'),
          translateMessage('ticketStatus_rejected'),
        ],
        id: 'statuses',
        categoryGapRatio: 0.9,
        barGapRatio: 1,
      },
    ];
  }, [translateMessage]);

  const yAxis = useMemo(() => {
    return [
      {
        id: 'tickets',
        label: translateMessage('ticketStatus_tickets'),
        min: minY,
        tickMinStep: 1,
      },
    ];
  }, [translateMessage, minY]);

  const series = useMemo(() => {
    return [
      { data: [pending, 0, 0], label: translateMessage('ticketStatus_pending'), stack: 'total', color: '#EDBE2F' },
      { data: [0, accepted, 0], label: translateMessage('ticketStatus_accepted'), stack: 'total', color: '#4FBB75' },
      { data: [0, 0, rejected], label: translateMessage('ticketStatus_rejected'), stack: 'total', color: '#E05448' },
    ];
  }, [translateMessage, pending, accepted, rejected]);

  useEffect(() => {
    getWidgetData();
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(getWidgetData, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval, getWidgetData]);

  useEffect(() => {
    const unsubscribe = refreshSubscribe(getWidgetData);
    return () => unsubscribe();
  }, [refreshSubscribe]);

  const handleKebabAction = () => {
    onKebabAction();
  };

  return (
    <Styled>
      <span className="title">{widget.title || widget.name}</span>
      <BarChart
        tooltip={{ trigger: 'item' }}
        axisHighlight={{ x: 'none' }}
        series={series}
        xAxis={xAxis}
        yAxis={yAxis}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: 'translate(-25px, 0)',
          },
          [`.${axisClasses.right} .${axisClasses.label}`]: {
            transform: 'translate(30px, 0)',
          },
          '& .MuiBarElement-root:nth-child(1)': {
            rx: '5',
            ry: '5',
          },
          '& .MuiBarElement-root:nth-child(2)': {
            rx: '5',
            ry: '5',
          },
          '& .MuiBarElement-root:nth-child(3)': {
            rx: '5',
            ry: '5',
          },
          '& .MuiChartsLegend-mark': {
            rx: '5px',
            ry: '5px',
          },
        }}
        margin={{ left: 70 }}
        slotProps={{
          legend: {
            direction: 'row',
            position: { vertical: 'top', horizontal: 'right' },
            padding: 0,
            itemGap: 16,
          },
        }}
      />
    </Styled>
  );
};

export default TicketCountByStatusBarChart;

import React, { useState } from 'react';
import { Localization } from 'connex-cds';
import { Field, useField } from 'formik';
import { StyledWidget, LeftButtons, RightButtons } from './styles';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { NumberInput } from '../../../../commons';

const WidgetCard = ({ widget }) => {
  if (!widget) return null;
  const widgetName = `selectedWidgets.${widget.widgetId}`;
  const [fieldInput, fieldMeta, fieldHelpers] = useField(widgetName);
  const [addingWidget, setAddingWidget] = useState(!!(fieldInput?.value?.length > 0));
  const translateMessage = Localization.useTranslateMessage();

  const handleAddWidget = async () => {
    setAddingWidget(true);
    fieldHelpers.setValue([widget]);
  };

  const handleRemoveWidget = async () => {
    setAddingWidget(false);
    fieldHelpers.setValue(null);
  };

  let widgetThumbnail;
  try {
    widgetThumbnail = require(`../../../../assets/widget-thumbnails/${widget.widgetId}.png`);
   }
   catch (e) {
    widgetThumbnail = require(`../../../../assets/widget-thumbnails/not-found.png`)
  }

  return (
    <StyledWidget className="widget">
      <LeftButtons>
        { addingWidget && (
          <CheckIcon className="widget-added" fontSize="large" />
        )}
        <img
          src={widgetThumbnail}
          alt={widget.name + ' Thumbnail'}
          title={widget.name + ' Thumbnail'}
          className="widget-thumbnail"
        />
        { widget.publish?.name || widget.name }
      </LeftButtons>
      <RightButtons>
        { addingWidget && (
          <Field
            labelStringId="addDashboardDialog_widgetTab_widget_count"
            component={NumberInput}
            onChange={(_, value) => {
              fieldHelpers.setValue(Array(value).fill(widget));
            }}
            // value={widgetCount}
            className="widget-count"
            name={widgetName}
          />
        )}
        { !addingWidget && (
          <Button
            className="add-widget-button"
            onClick={handleAddWidget}
          >
            <AddCircleIcon className="add-widget" fontSize="large" />
          </Button>
        )}
        { addingWidget && (
          <Button
            className="remove-widget-button"
            onClick={handleRemoveWidget}
          >
            <CancelIcon className="remove-widget" fontSize="large" />
          </Button>
        )}
      </RightButtons>
    </StyledWidget>
  );
};

export default WidgetCard;

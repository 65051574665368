import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const StyledWidget = styled(Box)`
  color: ${({ theme }) => theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[95] })};
  border: 1px solid gray;

  width: 440px;
  height: 80px;
  padding: 0px;

  filter: drop-shadow(0px 6px 3px rgba(101, 119, 136, 0.2));

  background: ${({ theme }) => theme.validateMode({ light: 'rgb(255, 255, 255)', dark: '#35313F' })};
  border: 1px solid ${({ theme }) => theme.validateMode({ light: '#CAC4D0', dark: '#938F99' })};
  border-radius: 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;

  .widget-added {
    padding-right: 10px;
    color: ${({ theme }) => theme.validateMode({ light: theme.colorsCA.green[50], dark: theme.colorsCA.green[50] })};
  }

  .widget-count {
    width: 70px;
    column-gap: 0px;
  }

  .add-widget {
    color: ${({ theme }) => theme.validateMode({ light: theme.colorsCA.blue[50], dark: theme.colorsCA.blue[50] })};
  }

  .remove-widget {
    color: ${({ theme }) => theme.validateMode({ light: theme.colorsCA.red[50], dark: theme.colorsCA.red[50] })};
  }

  .widget-thumbnail {
    width: 76px;
    margin-right: 16px;
    margin-left: 8px;
  }

  .add-widget-button {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }

  .remove-widget-button {
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
`;

export const LeftButtons = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export const RightButtons = styled(Box)`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0px;
`;

import React, { useState, useEffect, useCallback } from 'react';
import { Localization } from 'connex-cds';
import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

import SelectableMenu from '../../../../commons/SelectableMenu';
import { ReactComponent as DashboardIcon } from '../../../../assets/icons/DashboardDropdownIcon.svg';

const DashboardSelector = ({ value, onChange, options, actions }) => {
  const [selectedValue, setSelectedValue] = useState(value || null);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <SelectableMenu
      value={selectedValue}
      onChange={onChange}
      // This have the custom dashboardIcon but, there is some config pending to customize colors
      valueStartIcon={<SvgIcon component={DashboardIcon} inheritViewBox fontSize="inherit" />}
      options={options}
      renderOption={({ label, translate }) =>
        translate === false ? label : <Localization.Translate stringId={label} />
      }
      actions={actions}
      renderAction={({ label, translate }) =>
        translate === false ? label : <Localization.Translate stringId={label} />
      }
      withArrow
      withCheckMark
    />
  );
};

DashboardSelector.propTypes = {
  value: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string, translate: PropTypes.bool }),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string,
      translate: PropTypes.bool,
    })
  ).isRequired,
};

export default DashboardSelector;

import { useState, useMemo } from 'react';
import { pick } from 'lodash';
import { Localization, useNotification, Core } from 'connex-cds';
import { Formik, useFormikContext } from 'formik';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { AddOutlined, EditOutlined } from '@mui/icons-material';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import { defaultValues, validationSchema } from './form-config';
import WidgetTab from '../widget-tab';
import DashboardInfoTab from '../dashboard-info-tab';
import { useCreateDashboard, useUpdateDashboard } from '../../../../api/hooks';
import { useListWidgets } from '../../../../api/hooks/widgets';
import { DialogTitle } from '../../../../commons';
import { DialogStyled } from './styles';

const defaultWidgetsOptions = [
  { value: 'NO', label: 'NO' },
  { value: 'YES', label: 'YES' },
];

const DetailsDialog = ({ open = false, onClose, editDashboard, widgetList, areWidgetsLoading }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { mutateAsync: createDashboard } = useCreateDashboard();
  const { mutateAsync: updateDashboard } = useUpdateDashboard();
  const notification = useNotification();
  const translateMessage = Localization.useTranslateMessage();
  const { values, ...formikBag } = useFormikContext();
  const { isSubmitting, dirty, isValid } = formikBag;
  const ADD_DASHBOARD_TABS = [
    {
      stringId: 'addDashboardDialog_tabs_dashboardInfo',
      component: <DashboardInfoTab editDashboard={editDashboard} defaultWidgetsOptions={defaultWidgetsOptions} />,
      icon: <DriveFileRenameOutlineRoundedIcon />,
    },
    {
      stringId: 'general_widget',
      component: (
        <WidgetTab editDashboard={editDashboard} widgetList={widgetList} areWidgetsLoading={areWidgetsLoading} />
      ),
      icon: <WidgetsOutlinedIcon />,
    },
  ];

  const handleClose = response => {
    onClose(response);
    formikBag.resetForm();
    setCurrentTab(0);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const handleTabChange = (_, tabValue) => {
    setCurrentTab(tabValue);
  };

  const goToStep = stepIndex => () => {
    setCurrentTab(stepIndex);
  };

  const handleOnSubmit = async () => {
    const { name, defaultWidgets, selectedWidgets } = values;
    formikBag.setSubmitting(true);

    let widgets = editDashboard ? editDashboard.widgets : [];

    if (defaultWidgets?.value === 'YES') {
      widgets = widgets.concat([
        {
          widgetId: 'ticket-count-by-status',
          name: 'Ticket Count by Status',
          type: 'core-widget',
          options: { selectedOptions: { ticketStatus: 'pending' } },
          dataSource: 'rockset',
          queryIdentifier: 'ticket-count-by-status',
        },
        {
          widgetId: 'ticket-count-by-status',
          name: 'Ticket Count by Status',
          type: 'core-widget',
          options: { selectedOptions: { ticketStatus: 'accepted' } },
          dataSource: 'rockset',
          queryIdentifier: 'ticket-count-by-status',
        },
        {
          widgetId: 'ticket-count-by-status',
          name: 'Ticket Count by Status',
          type: 'core-widget',
          options: { selectedOptions: { ticketStatus: 'rejected' } },
          dataSource: 'rockset',
          queryIdentifier: 'ticket-count-by-status',
        },
        {
          widgetId: 'raw-material-requirements',
          name: 'Raw Material Requirements',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'raw-material-requirements',
        },
        {
          widgetId: 'cod-recap-by-payment-method',
          name: 'COD Recap By Payment Method',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'cod-recap-by-payment-method',
        },
        {
          widgetId: 'cod-recap-by-order',
          name: 'COD Recap By Order',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'cod-recap-by-order',
        },
        {
          widgetId: 'ticket-count-by-status-bar-chart',
          name: 'Ticket Count by Status (Bar Chart)',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'ticket-count-by-status',
        },
        {
          widgetId: 'location-production-statistics',
          name: 'Location Production Statistics',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'location-production-statistics',
        },
        {
          widgetId: 'location-production-by-product',
          name: 'Location Production by Product',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'location-production-by-product',
        },
        {
          widgetId: 'batch-variance-by-material-and-location',
          name: 'Batch Variance By Material And Location',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'batch-variance-by-material-and-location',
        },
        {
          widgetId: 'priced-orders-for-date-range',
          name: 'Priced Orders for Date Range',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'priced-orders',
        },
        {
          widgetId: 'customer-scorecard',
          name: 'Customer Scorecard',
          type: 'core-widget',
          options: { selectedSize: 'medium', selectedOptions: {} },
          dataSource: 'rockset',
          queryIdentifier: 'customer-scorecard-from-tickets',
        },
      ]);
    }
    const preparedWidgets = Object.values(selectedWidgets)
      .flat(Infinity)
      .map(widget => {
        return pick(widget, ['widgetId', 'name', 'type', 'options', 'dataSource', 'queryIdentifier']);
      });
    widgets = widgets.concat(preparedWidgets);

    let response;
    try {
      const mutateFn = editDashboard ? updateDashboard : createDashboard;
      const msg = editDashboard ? 'notification_changesSaved' : 'dashboardNotification_dashboardAdded';
      const dashboard = {
        ...editDashboard,
        crn: editDashboard?.crn,
        subjectRef: editDashboard?.subjectRef,
        name: name,
        status: 'ACTIVE',
        widgets: widgets,
      };
      response = await mutateFn({ dashboard });

      notification.success(translateMessage(msg));
    } catch (error) {
      console.error(error);
    }

    formikBag.setSubmitting(false);
    formikBag.resetForm();
    handleClose(response);
  };

  return (
    <DialogStyled className="add-dashboard-dialog" open={open} maxWidth="lg" fullWidth onClose={handleCloseDialog}>
      <DialogTitle className="dialog-title" onClose={handleClose}>
        {editDashboard ? <EditOutlined className="edit-icon" /> : <AddOutlined className="add-icon" />}
        <Localization.Translate
          stringId={editDashboard ? 'dashboards_editDashboard_title' : 'dashboards_addDashboard_title'}
        />
      </DialogTitle>
      <>
        <Tabs className="add-dashboard-dialog-tabs" value={currentTab} onChange={handleTabChange} variant="fullWidth">
          {ADD_DASHBOARD_TABS.map(({ stringId, icon }) => (
            <Tab key={stringId} icon={icon} label={<Localization.Translate stringId={stringId} />} />
          ))}
        </Tabs>
        <DialogContent className="dialog-content" dividers>
          <div className="tab-content">{ADD_DASHBOARD_TABS[currentTab].component}</div>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <div className="step-controls">
            {currentTab !== 0 && (
              <Button onClick={goToStep(currentTab - 1)}>
                <Localization.Translate stringId="addDashboardDialog_dialogAction_back" />
              </Button>
            )}
            {currentTab < ADD_DASHBOARD_TABS.length - 1 && (
              <Button onClick={goToStep(currentTab + 1)}>
                <Localization.Translate stringId="addDashboardDialog_dialogAction_next" />
              </Button>
            )}
          </div>
          <Button variant="contained" disabled={isSubmitting || !dirty || !isValid} onClick={handleOnSubmit}>
            <Localization.Translate stringId="addDashboardDialog_dialogAction_save" />
          </Button>
        </DialogActions>
      </>
    </DialogStyled>
  );
};

export default ({ editDashboard, ...props }) => {
  const { data: widgetList, isLoading: areWidgetsLoading } = useListWidgets();
  const initialValues = useMemo(() => {
    if (editDashboard) {
      const { id, name } = editDashboard;
      defaultValues.id = id;
      defaultValues.name = name;
      widgetList?.forEach(widget => {
        defaultValues.selectedWidgets[widget.widgetId] = [];
      });
    } else {
      defaultValues.name = '';
      defaultValues.selectedWidgets = {};
      widgetList?.forEach(widget => {
        defaultValues.selectedWidgets[widget.widgetId] = [];
      });
    }

    defaultValues.defaultWidgets = defaultWidgetsOptions.length ? defaultWidgetsOptions[0] : '';

    return defaultValues;
  }, [editDashboard, widgetList]);

  return (
    <Core.Spinner spin={areWidgetsLoading}>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        <DetailsDialog
          {...props}
          editDashboard={editDashboard}
          widgetList={widgetList}
          areWidgetsLoading={areWidgetsLoading}
        />
      </Formik>
    </Core.Spinner>
  );
};

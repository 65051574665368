import { css } from '@emotion/react';
import { alpha } from "@mui/material";

export default ({ theme }) => css`
  width: 40px;
  height: 40px;
  padding: 10px;
  color: ${theme.validateMode({
    light: theme.colorsCA.neutral[40],
    dark: theme.colorsCA.blue[95]
  })};
  background: ${theme.validateMode({
    light: theme.colorsCA.neutral[100],
    dark: theme.colorsCA.neutral[10]
  })};
  svg {
    animation: none;
  }

  &.refreshing {
    background-color: ${theme.validateMode({
      light: theme.colorsCA.blue[95],
      dark: theme.colorsCA.darkBlue[60]
    })};

    svg {
      animation: spin 4s linear infinite;
    }
  }

  &:hover {
    background: ${theme.validateMode({
      light: alpha(theme.colorsCA.darkBlue[10], 0.05),
      dark: alpha(theme.colorsCA.blue[70], 0.05),
    })};
  }
`;

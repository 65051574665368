import mixin from '../mixins';
import colors from '../colors';
import { shadow, background } from '../elevation';
import darkOverrides from './darkOverrides';
import lightOverrides from './lightOverrides';

// TODO: check theme extension possibility to avoid repetition for components overrides (like spacing, effects)
const getDesignConfig = mode => {
  const shadowCA = mixin.shadowTheme(mode);
  const backgroundCA = mixin.backgroundTheme(mode);
  const filterCA = mixin.filterTheme(mode);
  const surfaceCA = mixin.surfaceTheme(mode);
  const validateMode = mixin.validateMode(mode);
  const customOverrides = mode => (mode === 'light' ? lightOverrides : darkOverrides);

  return {
    // Colors to use in component styles, values might change between theme mode (dark, light)
    colorsCA: colors,
    shadowCA: shadowCA,
    backgroundCA: backgroundCA,
    filterCA: filterCA,
    surfaceCA: surfaceCA,
    validateMode: validateMode,
    elevation: {
      1: `box-shadow: ${shadow[mode][1]}; background:  ${background[mode][1]};`,
      2: `box-shadow: ${shadow[mode][2]}; background:  ${background[mode][2]};`,
      3: `box-shadow: ${shadow[mode][3]}; background:  ${background[mode][3]};`,
      4: `box-shadow: ${shadow[mode][4]}; background:  ${background[mode][4]};`,
      5: `box-shadow: ${shadow[mode][5]}; background:  ${background[mode][5]};`,
    },

    palette: {
      mode: mode,
      text: {
        primary: validateMode({ light: colors.neutral[40], dark: colors.neutral[95] }),
        secondary: validateMode({ light: colors.neutral[60], dark: colors.neutral[80] }),
      },
      primary: {
        main: validateMode({ light: colors.darkBlue[10], dark: colors.blue[90] }),
        contrastText: validateMode({ light: colors.neutral[100], dark: colors.blue[10] }),
      },
      secondary: {
        main: validateMode({ light: colors.blue[10], dark: colors.blue[95] }),
        contrastText: validateMode({ light: colors.neutral[100], dark: colors.blue[10] }),
      },
      info: {
        main: validateMode({ light: colors.blue[40], dark: colors.blue[40] }),
        contrastText: validateMode({ light: colors.neutral[100], dark: colors.neutral[40] }),
      },
      success: {
        main: validateMode({ light: colors.green[50], dark: colors.green[50] }),
        contrastText: validateMode({ light: colors.green[95], dark: colors.green[95] }),
      },
      background: {
        contrast: validateMode({ light: colors.neutral[80], dark: colors.neutral[60] }),
      },
      error: {
        main: validateMode({ light: colors.red[80], dark: colors.red[80] }),
        contrastText: validateMode({ light: colors.neutral[20], dark: colors.neutral[20] }),
      },
      warning: {
        main: validateMode({ light: colors.yellow[30], dark: colors.yellow[30] }),
        contrastText: validateMode({ light: colors.yellow[90], dark: colors.yellow[90] }),
      },
      danger: {
        main: validateMode({ light: colors.red[40], dark: colors.red[40] }),
        contrastText: validateMode({ light: colors.red[95], dark: colors.red[95] }),
      },
      link: {
        main: validateMode({ light: colors.blue[40], dark: colors.neutral[90] }),
      },
    },
    typography: {
      fontFamily: 'Work Sans',
      fontSize: 14,
      pxToRemMixin: mixin.pxToRem,
    },
    components: {
      MuiIconButton: {
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              border: `1px solid ${validateMode({ light: colors.neutral[40], dark: colors.blue[95] })}`,
            },
          },
        ],
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: validateMode({ light: colors.darkBlue[50], dark: colors.darkBlue[80] }),
            '&.Mui-checked': {
              color: validateMode({ light: colors.darkBlue[50], dark: colors.darkBlue[80] }),
            },
          },
        },
      },
      ...customOverrides(mode),
    },
  };
};

export default getDesignConfig;
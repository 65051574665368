import { Field } from 'formik';
import { Theme } from 'connex-cds';
import { TextInputWithFormikField, SelectWithFormikField } from '../../../../commons';
import styled from '@emotion/styled';

import styles from './styles';
import { ReactComponent as DashboardCreateImageLight } from '../../../../assets/DashboardCreateImageLight.svg';
import { ReactComponent as DashboardCreateImageDark } from '../../../../assets/DashboardCreateImageDark.svg';

const Styled = styled.div`
  ${styles}
`;

const DashboardInfoTab = ({ editDashboard, defaultWidgetsOptions }) => {
  const { themeName } = Theme.useThemeContext();

  return (
    <Styled className="dashboard-info">
      {themeName === 'light' ? <DashboardCreateImageLight /> : <DashboardCreateImageDark />}

      <div className="dashboard-info-form">
        <Field
          testId="addDashboard-name"
          labelStringId="addDashboardDialog_dashboardInfoTab_name"
          component={TextInputWithFormikField}
          type="text"
          variant="outlined"
          name="name"
          required
        />
        {!editDashboard ? (
          <Field
            testId="addDashboard-defaultWidgets"
            labelStringId="addDashboardDialog_dashboardInfoTab_defaultWidgets"
            component={SelectWithFormikField}
            name="defaultWidgets"
            options={defaultWidgetsOptions}
            infoTooltipStringId="addDashboardDialog_defaultWidgets_tooltip"
          />
        ) : (
          <></>
        )}
      </div>
    </Styled>
  );
};

export default DashboardInfoTab;

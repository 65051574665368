import React, { useState } from 'react';
import { Localization } from 'connex-cds';
import styled from '@emotion/styled';

import Button from '../../../../commons/Button';
import { ReactComponent as DashboardEmptyImage } from '../../../../assets/DashboardEmptyImage.svg';
import styles from './styles';

const Styled = styled.div`
  ${styles}
`;

const EmptyDashboard = ({ onAddDashboard }) => {
  const handleAddDashboard = () => {
    onAddDashboard();
  };

  return (
    <Styled>
      <DashboardEmptyImage />
      <span className="spotlight">
        <Localization.Translate stringId="addWidgetEmptyStateSpotlight" />
      </span>
      <span className="text">
        <Localization.Translate stringId="addWidgetInstructions" />
      </span>
      <div className="buttons-down">
        <Button className="create-dashboard" variant="outlined" onClick={handleAddDashboard}>
          <Localization.Translate stringId="createDashboard" />
        </Button>
      </div>
    </Styled>
  );
};

export default EmptyDashboard;

import { css } from '@emotion/react';
import { tooltipClasses } from '@mui/material/Tooltip';

export default ({ theme }) => css`
  z-index: 1500;

  .${tooltipClasses.tooltip} {
    background-color: ${theme.colorsCA.blue[30]};
    padding: 10px;
    color: ${theme.colorsCA.neutral[100]};
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .${tooltipClasses.arrow} {
    color: ${theme.colorsCA.blue[30]};
  }
`;
